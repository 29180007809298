export class Product {

    static getConfiguration(): object{
        return {
            "item_type": "products",
            "item_title": "Товары",
            "item_model": "Product",
            "hiddens": ["id"],
            "hiddens_obj": {
                "id": 'ID'
            },
            "inputs": ["name", "layout", "price_range"],
            "inputs_obj": {
                "name": 'Имя',
                "layout": 'Шаблон',
                "price_range": 'Ценовой диапазон',
            },
            "inputs_default": {
                "price_range": '0'
            },
            "labels": ["createdat", "updatedat"],
            "labels_obj": {
                "createdat": 'Создано',
                "updatedat": 'Обновлено'
            },
            "switches": ["ismain"],
            "switches_obj": {
                "ismain": 'Главный товар'
            },
            "switches_default": {
                "ismain": 1
            },
            "images": [
                {
                    "name": "Модификации",
                    "folder": 'store',
                    "rest": "productModesImages",
                    "model": "ProductModesImage",
                    "item_col": "product_has_images",
                    "set_output": (id1, id2) => ({product_id: id1, image_id: id2})
                }
            ],
            "files": [
                {
                    "name": "Документы",
                    "rest": "documents",
                    "model": "Document",
                    "item_col": "product_has_documents",
                    "set_output": (id1, id2) => ({product_id: id1, document_id: id2})
                }
            ],
            "relations_info": [
                {
                    "name": "Страницы товаров",
                    "rest": "productPages",
                    "model": "ProductPage",
                    "item_col": "productpage_has_products",
                    "set_output": (id1, id2) => ({product_id: id1, productpage_id: id2})
                },
                {
                    "name": "Модификация",
                    "rest": "modes",
                    "model": "Mode",
                    "item_col": "product_has_modes",
                    "free_type": "product_has_modes",
                    "set_output": (id1, id2) => ({product_id: id1, mode_id: id2})
                },
                // {
                //     "name": "Фильтр",
                //     "rest": "filters",
                //     "model": "Filter",
                //     "item_col": "filter_ids",
                //     "item_col_json": "filter_ids_json"
                // },
                // {
                //     "name": "Параметр",
                //     "rest": "parameters",
                //     "model": "Parameter",
                //     "item_col": "parameter_ids",
                //     "item_col_json": "parameter_ids_json"
                // }
            ],
            // "filters": ["isMainOn", "isMainOff", "priceRangeEmpty", "layoutEmpty"],
            // "filters_obj": {
            //     "isMainOn":{
            //         "label": "Главные товары",
            //         "paramName": '&filter[ismain]',
            //         "paramValues": [1]
            //     },
            //     "isMainOff":{
            //         "label": "Второстепенные товары",
            //         "paramName": '&filter[ismain]',
            //         "paramValues": [0]
            //     },
            //     "priceRangeEmpty": {
            //         "label": "C пустым цен. диапазоном",
            //         "paramName": '&filter[price_range]',
            //         "paramValues": ['']
            //     },
            //     "layoutEmpty": {
            //         "label": "C пустым шаблоном",
            //         "paramName": '&filter[layout]',
            //         "paramValues": ['']
            //     }
            // }
        }
    }

}