

  <!-- <div>{{ item_info.name }}</div>
  <div >{{ item_info.full_name }}</div>
  <div></div>
  <div class="">
    <div class="" *ngFor="let relation of configuration.relations; let i = index">
      <trm-relation-wrap [(active_values_ids)]="item_info[relation]" [relation]="relations[i]" [(disabled)]="configuration.item_type"></trm-relation-wrap> 
    </div>
  </div>
  <button (click)="onSave()">Обновить</button> -->


<div class="item" [ngStyle]="getStylesBySeq()">
    <!-- <button (click)="onOpen()" *ngIf="action == 'create'">Добавить</button> -->
    <!-- <div class="item_prev" (click)="onOpen()" *ngIf="action != 'create'">
      <div>
        <div class="item_img">
          <img src="img/rosma.jpg" alt="">
        </div>
        <div class="item_title">
          <h3>{{ item.name }}</h3>
        </div>
        <div class="btns">
          <button class="copy_btn" (click)="onCopy()">Копировать</button>
        </div>
      </div>
    </div> -->
    <div *ngIf="isOpened && item_info" class="item_info">
      <div>
        <div class="fixed_panel">
          <div class="cancel_btn"><button (click)="onCancel()">Закрыть</button></div>
          <div class="save_btn" *ngIf="isExistsInDb()"><button (click)="onSave()">Сохранить</button></div>
          <div class="save_n_quit_btn" *ngIf="isExistsInDb()"><button (click)="onSave(true)">Сохранить и выйти</button></div>
          <div class="save_btn" *ngIf="type == 'copy' || !isExistsInDb()"><button (click)="onCreate()">Добавить</button></div>
          <div class="save_n_quit_btn" *ngIf="type == 'copy' || !isExistsInDb()"><button (click)="onCreate(true)">Добавить и выйти</button></div>
        </div>
        <div class="item_info_row left">
          <div class="inputs_wrap" *ngFor="let el of configuration.inputs; let i = index">
            <div class="input_wrap">
              <label>{{ (configuration.inputs_obj[el]) ? configuration.inputs_obj[el] : el }}</label>
              <input type="text" value="{{ item_info[el] }}" (change)="onChange($event, el)">
            </div>
          </div>
          <hr *ngIf="configuration.textareas">
          <div class="textareas_wrap" *ngFor="let el of configuration.textareas; let i = index">
            <div class="textarea_wrap">
              <label>{{ (configuration.textareas_obj[el]) ? configuration.textareas_obj[el] : el }} </label>
              <textarea name="" id="" cols="30" rows="10" (change)="onChange($event, el)">{{item_info[el]}}</textarea>
            </div>
          </div>
          <hr *ngIf="configuration.switches">
          <div class="switches_wrap" *ngFor="let el of configuration.switches; let i = index">
            <div>
              <label>{{ (configuration.switches_obj[el]) ? configuration.switches_obj[el] : el }} </label>
              <input type="checkbox" value="{{ item_info[el] }}" [checked]="(item_info[el] == 1)" (change)="onChange($event, el, 'checkbox')"/>
            </div>
          </div>
          <hr *ngIf="configuration.labels">
          <div class="labels_wrap" *ngFor="let el of configuration.labels; let i = index">
            <h5>{{ (configuration.labels_obj[el]) ? configuration.labels_obj[el] : el }}: <span>{{ item_info[el] }}</span></h5>
          </div>
        </div>
        <div class="item_info_row right">
          <div class="images_wrap" *ngIf="configuration.images && isExistsInDb()">
            <trm-images-wrap (fileChange)="onRelationChanged($event)" [images]="item_info[configuration.images[0]['item_col']]" [info]="configuration.images[0]"></trm-images-wrap>
          </div>
          <div class="files_wrap" *ngIf="configuration.files && isExistsInDb()">
            <trm-files-wrap (fileChange)="onRelationChanged($event)" [file_ids]="item_info[configuration.files[0]['item_col']]" [info]="configuration.files[0]"></trm-files-wrap>
          </div>
          <hr>
          <div class="relations" *ngIf="isExistsInDb()">
            <div class="relations_wrap" *ngFor="let el of configuration.relations_info; let i = index">
              <trm-relation-wrap 
                (relationChange)="onRelationChanged($event)" 
                (relationAdd)="onRelationAdd($event)" 
                [active_values_ids]="item_info[el['item_col']]" 
                [relation]="relations[i]" 
                [(disabled)]="configuration.item_type" 
                [parent_id]="item_info.id" 
                [info]="el">
              </trm-relation-wrap>
            </div>
          </div>
          <div class="prices_wrap" *ngIf="item_info.id">
            <div *ngFor="let el of configuration.prices_info; let i = index">
              <trm-prices-wrap [modeId]="item_info.id" [warehouseIds]="item_info.warehouse_has_modes" [info]="el"></trm-prices-wrap>
            </div>
          </div>
        </div>
        <br clear="all">
      </div>
      
    </div>
    <!-- <ng-template #item2Container></ng-template> -->
  </div>