import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { SystemRoutingModule } from './system-routing.module';

import { SystemComponent } from './system.component';
import { ProductsService } from '../shared/services/products.service';
import { CmsPageComponent } from './cms/page/cms-page.component';
import { ItemsListModule } from './cms/module/items-list.module';
import { GqlService } from '../shared/gql/service/gql.service';
import { ImportPageComponent } from './import/page/import-page.component';
import { ImportCatalogComponent } from './import/components/import-catalog/import-catalog.component';
import { ImportWarehouseComponent } from './import/components/import-warehouse/import-warehouse.component';

@NgModule({
  imports: [
    CommonModule, 
    SharedModule, 
    SystemRoutingModule,
    ItemsListModule
  ],
  declarations: [
    SystemComponent,
    CmsPageComponent,
    ImportPageComponent,
    ImportCatalogComponent,
    ImportWarehouseComponent
  ],
  providers: [ProductsService, GqlService]
})
export class SystemModule { }