import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges
} from "@angular/core";
import { ProductsService } from "src/app/shared/services/products.service";
import { GqlService } from "src/app/shared/gql/service/gql.service";

@Component({
  selector: "trm-images-wrap",
  templateUrl: "./images-wrap.component.html",
  styleUrls: ["./images-wrap.component.sass"]
})
export class ImagesWrapComponent implements OnInit, OnChanges {
  @Input() images;
  @Input() info;
  @Output() fileChange = new EventEmitter<any>();
  images_list: any[] = [];
  count: number;
  max: number;

  constructor(
    // private productsService: ProductsService,
    private gqlService: GqlService
  ) {}

  ngOnInit() {}

  ngOnChanges() {
    // console.log(this.images)
    // console.log(this.item_col)
    console.log("Images. OnChanges");
    // преобразуем пришедшие данные
    if (typeof this.images === "string") {
      this.images_list = [];
    } else {
      this.images_list = this.images.map(v => Object.values(v)[0]);
      this.max = 50;
    }

    // this.constructImagesList()
    // console.log(typeof this.images)
    // console.log(this.images)
  }

  // constructImagesList(){
  //     if(typeof this.images === "string"){
  //       this.images_list = [{
  //         "id": -1,
  //         "name": this.images,
  //         "description": "Изображение"
  //       }]
  //       this.max = 1
  //     }else{
  //       this.images_list = this.images.map(v=>Object.values(v)[0])
  //       this.max = 50
  //     }
  // }

  onAdd() {}

  onClick() {
    console.log(this.images);
    console.log(this.info);
  }

  onFilesChosen(event) {
    let files = Array.from(event.target.files);
    event.target.value = ""; // ?

    if (this.max < files.length) return;

    this.gqlService
      .uploadFiles(files, "image", this.info.folder)
      // .uploadFiles(files, "image", "store")
      .subscribe(res => {
        console.log(res);
        // if(res)
        // this.images = [...this.images, ...res]
        this.images_list = [...this.images_list, ...res];
        this.fileChange.emit([this.images_list, this.info]);
      });
  }

  onDelete(image) {
    if (image[0]["id"] == -1) {
      // ?????
      this.images = null;
    } else {
      this.images_list = this.images_list.filter(img => {
        return !(img.id === image[0].id);
      });
      if (this.images_list.length == 0) {
        // this.images = undefined
      }
    }
  }

  onChooseMain(image) {
    // извлечь элемент из массива images с id
    // добавить его в начало
    // var index = this.images_list.indexOf(String(image[0]['id']));
    // if (index > -1) {
    //   this.images_list.splice(index, 1);
    //   this.images_list.unshift(String(image[0]['id']))
    // }
  }

  onFileChanged(image) {
    console.log(image[0]);
    switch (image[1]) {
      case "delete":
        this.onDelete(image);
        break;
      case "main":
        this.onChooseMain(image);
        break;
      default:
        break;
    }

    // this.constructImagesList()
    this.fileChange.emit([this.images_list, this.info]);
    console.log(this.images_list);
  }
}
