<div>
  <div class="title">
    <h4 (click)="onClick()">Изображения</h4>
    <div class="images_btn">
      <div>
        <div class="add_files">
          <input
            type="file"
            name="file-2[]"
            id="file-2"
            class="inputfile inputfile-2"
            data-multiple-caption="{count} files selected"
            multiple
            (change)="onFilesChosen($event)"
          />
          <label for="file-2"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="17"
              viewBox="0 0 20 17"
            >
              <path
                d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"
              />
            </svg>
            <span>Выберите</span></label
          >
        </div>
      </div>
    </div>
  </div>
  <div class="images" *ngIf="images_list.length > 0">
    <div *ngFor="let image of images_list; let i = index">
      <trm-image-wrap
        (fileChange)="onFileChanged($event)"
        [(image)]="images_list[i]"
        [folder]="info.folder"
      ></trm-image-wrap>
    </div>
  </div>
  <div class="no_images" *ngIf="images_list.length == 0">
    <h4>Изображений пока нет</h4>
  </div>
  <br clear="all" />
</div>
