import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
// import { ProductsService } from '../../shared/services/products.service';
// import { Category } from '../../shared/models/category.model';
import io from "socket.io-client";
import SocketIOFileClient from "socket.io-file-client";

@Component({
  selector: "trm-import-page",
  templateUrl: "./import-page.component.html",
  styleUrls: ["./import-page.component.sass"]
})
export class ImportPageComponent implements OnInit, OnDestroy {
  items_type: string;

  url: string;
  url_old: string;

  url_arr: string[];

  constructor(private router: Router) {}

  socket = null;
  uploader = null;

  results = []

  ngOnInit() {
    // тут можем сделать ws client
    this.socket = io("http://api.овенспб.рф:3002");
    this.socket.on('importCatalog', (data) => {
      console.log(data);
      this.results = data.msg || []
    })
    this.socket.on('importPrice', (data) => {
      console.log(data);
      this.results = data.msg || []
    })
    this.socket.on('importWarehouse', (data) => {
      console.log(data);
      this.results = data.msg || []
    })
    this.uploader = new SocketIOFileClient(this.socket);
    this.uploader.on("start", function(fileInfo) {
      console.log("Start uploading", fileInfo);
    });
    this.uploader.on("stream", function(fileInfo) {
      console.log("Streaming... sent " + fileInfo.sent + " bytes.");
    });
    this.uploader.on("complete", function(fileInfo) {
      console.log("Upload Complete", fileInfo);
    });
    this.uploader.on("error", function(err) {
      console.log("Error!", err);
    });
    this.uploader.on("abort", function(fileInfo) {
      console.log("Aborted: ", fileInfo);
    });
  }

  ngDoCheck() {
    this.url = this.router.url;
    if (this.url !== this.url_old) {
      this.results = []
      this.url_old = this.url;
      this.url_arr = this.url.split("/");
      if (!this.url_arr[3]) {
        this.items_type = "catalog";
      } else {
        this.items_type = this.url_arr[3];
      }
    }
  }

  ngOnDestroy(){
    this.socket.close()
    this.uploader = null
    this.socket = null
  }

  onSubmit(files) {
    const eventName =
      this.items_type === "catalog"
        ? "importCatalog"
        : this.items_type === "price"
        ? "importPrice"
        : this.items_type === "warehouse"
        ? "importWarehouse"
        : null;

    if(this.socket && eventName){
      console.log(eventName, files);
      if(this.uploader && files && files[0]){
        var uploadIds = this.uploader.upload(
          { files },
          {
            data: {
              type: eventName
            }
          }
        );
        console.log(uploadIds);
      }else{
        this.socket.emit(
          eventName,
          {}
        )
      }
    }

  }
}
