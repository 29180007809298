import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { ProductsService } from '../../shared/services/products.service';
// import { Category } from '../../shared/models/category.model';

@Component({
  selector: 'trm-cms-page',
  templateUrl: './cms-page.component.html',
  styleUrls: ['./cms-page.component.sass']
})
export class CmsPageComponent implements OnInit {

  items_type: string

  url: string
  url_old: string

  url_arr: string[]

  constructor(private router: Router) { 
  }

  ngOnInit(){

  }
  
  ngDoCheck() {

    this.url = this.router.url

    if(this.url !== this.url_old){
      this.url_old = this.url
      this.url_arr = this.url.split("/")
      // console.log(this.url_arr[3] == undefined)
      if(!this.url_arr[3]){
        this.items_type = "categories"
      }else{
        this.items_type = this.url_arr[3]
      }
      // console.log(this.items_type)
    }
    

  }

}
