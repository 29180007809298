export class Filter {

    static getConfiguration(): object{
        return {
            "item_type": "filters",
            "item_title": "Фильтры",
            "item_model": "Filter",
            "hiddens": ["id"],
            "hiddens_obj": {
                "id": 'ID'
            },
            "inputs": ["name"],
            "inputs_obj": {
                "name": 'Имя'
            },
            // "textareas": ["description","descr","prev_descr","specification"],
            "switches": ["isLayout"],
            "switches_obj": {
                "isLayout": 'Для модификаций?',
            },
            // "labels": ["price_range","updatedAt","createdAt","visibility","rating"],
            "relations_info": [
                {
                    "name": "Родительские категории",
                    "rest": "categories",
                    "model": "Category",
                    "item_col": "category_ids",
                    "item_col_json": "category_ids_json"
                },
                {
                    "name": "Товары",
                    "rest": "products",
                    "model": "Product",
                    "item_col": "product_ids",
                    "item_col_json": "product_ids_json"
                },
                {
                    "name": "Параметры",
                    "rest": "parameters",
                    "model": "Parameter",
                    "item_col": "parameter_ids",
                    "item_col_json": "parameter_ids_json"
                }
            ]
        }
    }

}