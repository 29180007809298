import { Component, OnInit, Input } from '@angular/core';
import 'rxjs/add/observable/fromEvent';
import { ProductsService } from 'src/app/shared/services/products.service';
import { GqlService } from 'src/app/shared/gql/service/gql.service';
// import { keyframes } from '../../../../node_modules/@angular/animations/src/animation/dsl';

@Component({
  selector: 'trm-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.sass']
})
export class TableComponent implements OnInit {


  @Input() columns: any[] = []
  @Input() configuration: any = {}

  @Input() getRows: any
  @Input() onAction: any

  editing = {};

  page = {
    curPage: 0,
    pageCount: 0,
    totalCount: 150,
    perPage: 20
  }
  // sorts: any[] = [{prop: 'id', dir: 'asc'}]
  sorts: any[] = [{prop: 'createdat', dir: 'desc'}]
  searchQuery: string = ''
  filter: string
  filters: any[] = []
  rows: any[] = []
  loading: boolean = false
  selected = []

  constructor(
    private productsService: ProductsService,
    private gqlService: GqlService
  ){}
  
  // инициализировать внешний вид 
  // custom columns (select, delete, copy, remove with popup)
  // получать первоначальные данные - columns, rows
  // обработать события


  onSort(event){
    // redirect to itemslist and wait for answer
    this.loading = true

    this.page.curPage = 0 
    this.getRows({
      page: this.page.curPage, 
      sorts: event.sorts, 
      perPage: this.page.perPage, 
      search: this.searchQuery, 
      filters: this.filters
    }).then(({rows})=>{
      console.log(rows)
      this.sorts = event.sorts
      this.rows = rows
      this.loading = false
    })
    
  }
  onSelect({selected}){
    console.log('Select Event', selected, this.selected)

    this.selected.splice(0, this.selected.length)
    this.selected.push(...selected)
  }
  onActivate(event) {
  }


  onSearch(query: string){
    // redirect to itemslist and wait for answer
    this.loading = true
    this.page.curPage = 0 

    this.getRows({page: this.page.curPage, sorts: this.sorts, perPage: this.page.perPage, search: query, filters: this.filters}).then((res)=>{
      this.searchQuery = query
      this.rows = res.rows
      this.page = res.pageInfo
      this.loading = false
    })
  }
  onToggleFilter(checked, data){
    console.log(checked, data, this.filters)
    this.loading = true
    this.page.curPage = 0
    // const str = data.paramName + '=' + data.paramValues[0]
    // if(checked){
    //   this.filters.push({
    //     [data.paramName]: data.paramValues[0]
    //   })
    // }else{
    //   this.filters = this.filters.filter((filter)=>{
    //     return filter[] !== str
    //   })
    // }
    console.log(this.filters)

    this.getRows({page: this.page.curPage, perPage: this.page.perPage, sorts: this.sorts, search: this.searchQuery, filters: this.filters}).then((res)=>{
      this.rows = res.rows
      this.page = res.pageInfo
      this.loading = false
    })
  }
  onFilter(){
    // redirect to itemslist and wait for answer
  }
  onChoosePage(pageInfo){
    // redirect to itemslist and wait for answer
    this.loading = true;

    this.page.curPage = pageInfo.offset

    this.getRows({page: this.page.curPage, sorts: this.sorts, perPage: this.page.perPage, search: this.searchQuery, filters: this.filters }).then((res)=>{
      this.rows = res.rows
      this.loading = false
    })
  }
  onNextPage(page: number){
    // redirect to itemslist and wait for answer
  }

  onAdd(){
    this.onAction(undefined, 'create').then((row)=>{
      // console.log(row)
      if(!row) return
      this.rows = [ row, ...this.rows ]
      this.page.totalCount = this.page.totalCount + 1
      this.page.pageCount = Math.floor(this.page.totalCount/this.page.perPage)
      console.log('Rows quantity after add: ', this.rows.length)
    })
  }

  onOpen(id){
    // redirect to itemslist and wait for answer
    console.log(id)
    this.onAction(id, 'open').then((res)=>{
      if(!res) return
      console.log(res)
      this.rows = this.rows.map((row)=>{
        if(row.id === id){
          return res
        }else{
          return row
        }
      })
    })
    // дождаться закрытия и обновить
  }
  onCopy(id){
    // redirect to itemslist and wait for answer
    console.log(id)
    this.onAction(id, 'copy')
  }
  onRemove(id){
    // redirect to itemslist and wait for answer
    console.log(id)
    this.onAction(id, 'delete').then((res)=>{
      console.log(res)
      if(res){
        this.rows = this.rows.filter((row)=>{
          return (row.id !== id)
        })
        this.page.totalCount -= 1
        if(this.rows.length === 0){
          this.page.pageCount -= 1
          this.onChoosePage({offset: this.page.pageCount})
        }
        // this.page.pageCount = Math.floor(this.page.totalCount/this.page.perPage)
      }
    })
  }
  onRemoveSelected(){
    // redirect to itemslist and wait for answer
  }

  onChangeField(event: any, cell: any, rowIndex: any, row: any ){
    // redirect to itemslist and wait for answer
    console.log(event.target.value, cell, rowIndex, row[cell])
    this.editing[rowIndex + '-' + cell] = false

    console.log({[cell]: event.target.value}, this.configuration.item_type, row.id)

    this.gqlService.updateItem({[cell]: event.target.value}, this.configuration.item_type, row.id).subscribe(
      (item: any) => {
        console.log(item)
        this.rows[rowIndex][cell] = item[cell]
        this.rows = [...this.rows]
      },
      (error) => {
        console.log(error)
      }
    )

    // this.productsService.updateItem({[cell]: event.target.value}, this.configuration.item_type, row.id)
    //   .subscribe(
    //     (item: any) => {
    //       console.log(item)
    //       this.rows[rowIndex][cell] = item[cell]
    //       this.rows = [...this.rows]
    //     },
    //     (error) => {
    //     }
    //   )


  }

  onUpdate(){
    this.loading = true
    this.getRows({page: this.page.curPage, perPage: this.page.perPage, sorts: this.sorts, search: this.searchQuery, filters: this.filters}).then((res)=>{
      this.rows = res.rows
      this.page = res.pageInfo
      this.loading = false
    })
  }


  ngOnInit() {
    this.loading = true
    this.getRows({page: this.page.curPage, perPage: this.page.perPage, sorts: this.sorts, search: this.searchQuery, filters: this.filters}).then((res)=>{
      this.rows = res.rows
      this.page = res.pageInfo
      this.loading = false
    })
  }

}
