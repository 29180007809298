import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'trm-image-wrap',
  templateUrl: './image-wrap.component.html',
  styleUrls: ['./image-wrap.component.sass']
})
export class ImageWrapComponent implements OnInit {

  @Input() image: any
  @Input() folder: string
  @Input() isMain: boolean = true

  src: string = ''
  isLocal: boolean = true

  @Output() fileChange = new EventEmitter<any>();
  onFileChange(type){
    this.fileChange.emit([this.image, type]);
  }

  constructor() { }

  ngOnInit() {
    const name = this.image.name
    var re=new RegExp('^http(?::\\/\\/|s:\\/\\/).*?\\.(?:gif|jpg|png|svg)$', 'i')
    var m=re.exec(name)
    // console.log(name)
    if(m){
      this.src = name
      this.isLocal = false
    }else{
      // console.log(this.folder, name)
      // this.src = 'https://api.термоника.рф/eestore/uploads/img/' + this.folder + '/' + name
      this.src = 'https://uploads.овенспб.рф/unsafe/300x0/filters:format(webp)/' + this.folder + '/' + name
      this.isLocal = true
    }
  }

  onDelete(){
    // remove image from server
    // this.image = []
    this.onFileChange('delete')
  }

  onMainChange(){
    this.onFileChange('main')
  }

}
