

<div [class.fixed]="isFixed" class="items_wrap">

  <p-confirmDialog #cd header="Удаление записи" icon="pi pi-exclamation-triangle">
    <p-footer>
      <button class="ui-button-danger" type="button" pButton icon="pi pi-times" label="Нет" (click)="cd.reject()"></button>
      <button class="ui-button-success" type="button" pButton icon="pi pi-check" label="Да" (click)="cd.accept()"></button>
    </p-footer>
  </p-confirmDialog>
  
  <ng-template #itemsTable></ng-template>
  
</div>

<ng-template #itemContainer></ng-template>