<p class="text-xs-center">Регистрация для получения доступа</p>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div 
    class="form-group"
    [ngClass]="{'has-error': form.get('email').invalid && form.get('email').touched}"
  >
    <label for="email">Email</label>
    <input 
      type="text" 
      class="form-control underlined" 
      id="email" 
      placeholder="Введите email"
      formControlName="email"
    >
    <span class="form-help-text" *ngIf="form.get('email').invalid && form.get('email').touched">
      <span *ngIf="form.get('email')['errors']['required']">Email is empty</span>
      <span *ngIf="form.get('email')['errors']['email']">Thats not email</span>
      <span *ngIf="form.get('email')['errors']['forbiddenEmail']">Email already exists</span>
    </span>
  </div>
  <div 
    class="form-group"
    [ngClass]="{'has-error': form.get('password').invalid && form.get('password').touched}"
  >
    <label for="password">Пароль</label>
    <input 
      type="password" 
      class="form-control underlined" 
      id="password" 
      placeholder="Введите пароль"
      formControlName="password"
    >
    <span class="form-help-text" *ngIf="form.get('password').invalid && form.get('password').touched">
      <span *ngIf="form.get('password')['errors']['required']">Password is empty</span>
      <span *ngIf="form.get('password')['errors']['minlength'] && form.get('password')['errors']['minlength']['requiredLength']">
        Password is {{ form.get('password')['errors']['minlength']['actualLength'] }} symbols
      </span>
    </span>
  </div>
  <div 
    class="form-group"
    [ngClass]="{'has-error': form.get('name').invalid && form.get('name').touched}"
  >
    <label for="name">Имя</label>
    <input 
      type="text" 
      class="form-control underlined" 
      id="name" 
      placeholder="Введите имя"
      formControlName="name"
    >
    <span class="form-help-text" *ngIf="form.get('name').invalid && form.get('name').touched">
      Name is empty
    </span>
  </div>
  <div 
    class="form-group"
    [ngClass]="{'has-error': form.get('agree').invalid && form.get('agree').touched}"
  >
    <label for="agree">
      <input 
        class="checkbox" 
        id="agree" 
        type="checkbox"
        formControlName="agree"
      >
      <span>Согласен с правилами</span>
    </label>
  </div>
  <div class="form-group">
    <button 
      type="submit" 
      class="btn btn-block btn-primary"
      [disabled]="form.invalid"
    >
      Зарегистрироваться
    </button>
  </div>
  <div class="form-group">
    <p class="text-muted text-xs-center">
      Уже есть аккаунт?
      <a [routerLink]="'/login'">
        Войти!
      </a>
    </p>
  </div>
</form>