import gql from "graphql-tag";

import { Model } from "./model.interface";

// const getMany = gql`
//   query getModes(
//     $limit: Int
//     $where: mode_bool_exp
//     $order_by: [mode_order_by!]
//     $offset: Int
//   ) {
//     mode(limit: $limit, where: $where, order_by: $order_by, offset: $offset) {
//       id
//       name
//       full_name
//       modification
//       visibility
//       rating
//       isstorageposition
//       guid
//       createdat
//       updatedat
//     }
//     mode_aggregate(where: $where) {
//       aggregate {
//         count
//       }
//     }
//   }
// `;

// const getOne = gql`
//   query getMode($id: bigint!) {
//     mode_by_pk(id: $id) {
//       id
//       name
//       modification
//       full_name
//       description
//       meta_descr
//       meta_keywords
//       isstorageposition
//       visibility
//       rating
//       createdat
//       updatedat
//       product_has_modes {
//         product {
//           id
//           name
//         }
//       }
//       mode_has_images {
//         image {
//           id
//           name
//         }
//       }
//       warehouse_has_modes {
//         warehouse {
//           id
//           name
//           shipping_time
//           city
//         }
//         id
//         price
//         days
//         quantity
//         isavailable
//       }
//     }
//   }
// `;

// const createOne = gql`
//   mutation createMode($data: [mode_insert_input!]!) {
//     insert_mode(objects: $data) {
//       returning {
//         id
//         name
//         modification
//         full_name
//         description
//         meta_descr
//         meta_keywords
//         isstorageposition
//         visibility
//         rating
//         createdat
//         updatedat
//         product_has_modes {
//           product {
//             id
//             name
//           }
//         }
//         mode_has_images {
//           image {
//             id
//             name
//           }
//         }
//         warehouse_has_modes {
//           warehouse {
//             id
//             name
//             shipping_time
//             city
//           }
//           id
//           price
//           days
//           quantity
//           isavailable
//         }
//       }
//     }
//   }
// `;

// const createMany = gql`
//   mutation createModes($data: [mode_insert_input!]!) {
//     insert_mode(objects: $data) {
//       returning {
//         id
//         name
//       }
//     }
//   }
// `;
const updateOne = gql`
  mutation updateImage($id: bigint!, $data: uploads_image_set_input!) {
    update_uploads_image(_set: $data, where: { id: { _eq: $id } }) {
      returning {
        id
        name
        thumb
        description
        created_at
        updated_at
      }
    }
  }
`;
// const updateWithRelations = gql`
//   mutation updateModeWithRelations(
//     $where: mode_bool_exp!
//     $data: mode_set_input!
//   ) {
//     update_mode(_set: $data, where: $where) {
//       returning {
//         id
//         name
//       }
//     }
//   }
// `;
// const deleteOne = gql`
//   mutation deleteMode($id: bigint!) {
//     delete_mode(where: { id: { _eq: $id } }) {
//       returning {
//         id
//         name
//       }
//     }
//   }
// `;
// const deleteMany = gql`
//   mutation deleteModes($where: mode_bool_exp!) {
//     delete_mode(where: $where) {
//       returning {
//         id
//         name
//       }
//     }
//   }
// `;

export const Image = {
  getOne: null,
  getMany: null,
  createOne: null,
  createMany: null,
  updateOne,
  updateWithRelations: null,
  deleteOne: null,
  deleteMany: null
};
