<div class="relation_wrap">
  <div class="relation_title">
    <h4>{{ info.name }}</h4>
  </div>
  <div class="relation_wrap_content">
    <div class="end_values">
      <div *ngIf="isTotalOpened">
        <ngx-datatable
          class="dark"
          [rows]="items"
          [columnMode]="'force'"
          [headerHeight]="50"
          [footerHeight]="false"
          [rowHeight]="'auto'"
          [loadingIndicator]="loading"
          [limit]="10"  
        >
          <ngx-datatable-column prop="warehouse.name" name="Склад"></ngx-datatable-column>
          
          <ngx-datatable-column prop="price" name="Цена">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row" let-value="value">
              <input type="text" name="price" value="{{ value }}" (change)="onChange(rowIndex, $event)">
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column prop="days" name="Сроки">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row" let-value="value">
              <input type="text" name="days" value="{{ value }}" (change)="onChange(rowIndex, $event)">
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column prop="isavailable" name="Доступность">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row" let-value="value">
              <input type="checkbox" name="isavailable" [checked]="value == 1" (change)="onChange(rowIndex, $event)">
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column prop="quantity" name="Количество">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row" let-value="value">
              <input type="text" name="quantity" value="{{ value }}" (change)="onChange(rowIndex, $event)">
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Сохранение">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row" let-value="value">
              <button type="text" (click)="onSave(rowIndex, row)">Сохранить</button>
            </ng-template>
          </ngx-datatable-column>

        </ngx-datatable>
      </div>
    </div>
  </div>
</div>