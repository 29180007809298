import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { User } from '../../shared/models/user.model';
import { Message } from '../../shared/models/message.model';
import { Router, ActivatedRoute, Params } from '@angular/router';
// import { UsersService } from '../../shared/services/users.service';
import { AuthService } from '../../shared/services/auth.service';
import { ProductsService } from 'src/app/shared/services/products.service';

@Component({
  selector: 'trm-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {

  form: FormGroup;
  message: Message;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    // private productsService: ProductsService,
  ) { }

  ngOnInit() {

    this.message = new Message('danger', '');

    this.route.queryParams
      .subscribe((params: Params) => {
        if(params['nowCanLogin']) {
          this.showMessage({
            text: 'Now you can login', 
            type: "success"
          });
        }
      })

    this.form = new FormGroup({
      'email': new FormControl('', [Validators.required, Validators.email]),
      'password': new FormControl('', [Validators.required, Validators.minLength(6)])
    })
  }

  private showMessage(message: Message){
    this.message = message;
    window.setTimeout(() => {
      this.message.text = '';
    }, 5000);
  }

  onSubmit(){
    const formData = this.form.value;

    this.authService.login(formData.email, formData.password).subscribe(res=>{
      if(res){
        this.router.navigateByUrl('')
      }
    })

    // console.log(formData)
    // this.productsService.getToken(formData.email, formData.password)
    //   .subscribe((response: any) => {
    //     console.log(response)
    //     if(response.error || !response.workerId || !response.token){
    //       // ошибка входа
    //     }else{
    //       this.productsService.setToken(response.token)
    //       this.authService.login()
    //       this.router.navigateByUrl('/system/cms/categories')
    //     }
    //   })
    
  }

}
