<div>
  <!-- 
    https://github.com/swimlane/ngx-datatable/blob/master/demo/selection/selection-chkbox-template.component.ts
    https://github.com/swimlane/ngx-datatable/blob/master/demo/sorting/sorting-server.component.ts
    https://github.com/swimlane/ngx-datatable/blob/master/demo/paging/paging-server.component.ts
    https://github.com/swimlane/ngx-datatable/blob/master/demo/paging/scrolling-server.component.ts
    https://github.com/swimlane/ngx-datatable/blob/master/demo/basic/inline.component.ts
  -->

  <!-- [columns]="[{name:'Name'},{name:'Gender'},{name:'Company'}]"
    
    for scroll paging
    [scrollbarV]="true"
    (scroll)="onScroll($event.offsetY)"
 -->
 <div class="main_panel">
    <div class="main_panel_title">
      <h1>{{ configuration.item_title }}</h1>
      <button (click)="onUpdate()"><i class="fa fa-refresh fa-2x"></i></button>
    </div>
    <div class="helpers">
      <div>
        <button (click)="onRemoveSelected()" *ngIf="selected.length > 0">
          Удалить выбранные <i class="fa fa-trash"></i>
        </button>
      </div>
      <div>
        <input type="text" placeholder="Поиск" (keyup)="onSearch($event.target.value)">
      </div>
    </div>
  </div>
  <div class="filters">
    <div *ngFor="let filter of configuration.filters">
      <label>{{configuration.filters_obj[filter].label}}</label>
      <input type="checkbox" (change)="onToggleFilter($event.target.checked, configuration.filters_obj[filter])" />
    </div>
  </div>
   
    <ngx-datatable
    class="material"
    [rows]="rows"
    [columnMode]="'force'"
    [headerHeight]="50"
    [footerHeight]="50"
    [rowHeight]="'auto'"
    [loadingIndicator]="loading"

    [externalSorting]="true"
    [sorts]="sorts"
    (sort)="onSort($event)"
    sortType="multi"

    [externalPaging]="true"
    [count]="page.totalCount"
    [offset]="page.curPage"
    [limit]="page.perPage"
    (page)='onChoosePage($event)'

    [selected]="selected"
    [selectionType]="'checkbox'"
    (activate)="onActivate($event)"
    (select)='onSelect($event)'
    
    >
      <ngx-datatable-column [width]="30" [sortable]="false" [canAutoResize]="false" [draggable]="false" [resizeable]="false">
          <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
            <input type="checkbox" [checked]="allRowsSelected" (change)="selectFn(!allRowsSelected)"/>
          </ng-template>
          <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
            <input type="checkbox" [checked]="isSelected" (change)="onCheckboxChangeFn($event)"/>
          </ng-template>
      </ngx-datatable-column>
      
      <!-- <ngx-datatable-column *ngFor="let column of columns; let i = index" [prop]="column.prop" [name]="column.name"></ngx-datatable-column> -->
      
      <ngx-datatable-column *ngFor="let column of columns; let i = index" [prop]="column.prop" [name]="column.name">
        <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row" let-value="value">
          <span
            title="Double click to edit"
            (dblclick)="editing[rowIndex + '-'+ column.prop] = true"
            *ngIf="!editing[rowIndex + '-'+ column.prop]">
            {{value}}
          </span>
          <input 
            *ngIf="editing[rowIndex + '-'+ column.prop]"
            type="text" 
            [value]="value" 
            (blur)="editing[rowIndex + '-'+ column.prop] = false"
            (change)="onChangeField($event, column.prop, rowIndex, row)" 
          />
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column prop="id" name="" [width]="100" [sortable]="false" [canAutoResize]="false" [draggable]="false" [resizeable]="false">
        <ng-template ngx-datatable-cell-template let-value="value" >
          <button (click)="onOpen(value)">
            <i class="fa fa-edit"></i>
          </button>
          <button (click)="onRemove(value)">
            <i class="fa fa-trash"></i>
          </button>
          <!-- <button (click)="onCopy(value)">
            <i class="fa fa-copy"></i>
          </button> -->
        </ng-template>
      </ngx-datatable-column>

    </ngx-datatable>
    
    <div class="addItemBtn" (click)="onAdd()">
      <button><i class="fa fa-plus fa-2x"></i></button>
    </div>

    <!-- <div class='selected-column'>
      <h4>Selections <small>({{selected?.length}})</small></h4>
      <ul>
        <li *ngFor='let sel of selected'>
          {{sel.name}}
        </li>
        <li *ngIf="!selected?.length">No Selections</li>
      </ul>
    </div> -->
</div>