import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'trm-import-catalog',
  templateUrl: './import-catalog.component.html',
  styleUrls: ['./import-catalog.component.sass']
})
export class ImportCatalogComponent implements OnInit {

  @Input() results = []
  @Output() output = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onSubmit(){
    this.output.emit()
  }

}
