<div class="image_wrap_content">
  <div class="btns top">
    <!-- <button class="btn success" (click)="onMainChange()">Сделать главной</button> -->
    <button class="btn success small" (click)="onMainChange()"><i class="fa fa-plus"></i></button>
  </div>
  <img src="{{ src }}" alt="{{ image.description }}">
  <!-- <div style="background-image: url('assets/img/{{ image }}')"></div> -->
  <div class="btns btm" *ngIf="isLocal">
    <button class="btn error small" (click)="onDelete()"><i class="fa fa-trash"></i></button>
  </div>
</div>