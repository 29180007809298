import gql from "graphql-tag";

//??????? имя ???
export const category_has_categories = gql` 
  mutation updateCategoryHasCategories(
    $where: category_has_category_bool_exp!, 
    $data: [category_has_category_insert_input!]!
  ) {
    delete_category_has_category(where: $where) {
      affected_rows
    }
    insert_category_has_category(
      objects: $data
    ) {
      returning {
        parent_id
        daughter_id
      }
    }
  }
`;

export const category_has_productpages = gql` 
  mutation updateCategoryHasProductPages(
    $where: category_has_productpage_bool_exp!, 
    $data: [category_has_productpage_insert_input!]!
  ) {
    delete_category_has_productpage(where: $where) {
      affected_rows
    }
    insert_category_has_productpage(
      objects: $data
    ) {
      returning {
        category_id
        productpage_id
      }
    }
  }
`;

export const productpage_has_products = gql` 
  mutation updateProductPageHasProducts(
    $where: productpage_has_product_bool_exp!, 
    $data: [productpage_has_product_insert_input!]!
  ) {
    delete_productpage_has_product(where: $where) {
      affected_rows
    }
    insert_productpage_has_product(
      objects: $data
    ) {
      returning {
        productpage_id
        product_id
      }
    }
  }
`;

export const product_has_modes = gql`
  mutation updateProductHasMode(
    $where: product_has_mode_bool_exp!, 
    $data: [product_has_mode_insert_input!]!
  ) {
    delete_product_has_mode(where: $where) {
      affected_rows
    }
    insert_product_has_mode(
      objects: $data
    ) {
      returning {
        product_id
        mode_id
      }
    }
  }
`;

export const warehouse_has_modes = gql` 
mutation updateWarehouseHasMode(
  $where: warehouse_has_mode_bool_exp!, 
  $data: [warehouse_has_mode_insert_input!]!
) {
  delete_warehouse_has_mode(where: $where) {
    affected_rows
  }
  insert_warehouse_has_mode(
    objects: $data
  ) {
    returning {
      mode_id
      warehouse_id
    }
  }
}
`;


export const category_has_images = gql` 
mutation updateCategoryHasImages(
  $where: uploads_category_has_image_bool_exp!, 
  $data: [uploads_category_has_image_insert_input!]!
) {
  delete_uploads_category_has_image(where: $where) {
    affected_rows
  }
  insert_uploads_category_has_image(
    objects: $data
  ) {
    returning {
      category_id
      image_id
    }
  }
}
`;
export const productpage_has_images = gql` 
mutation updateProductPageHasImages(
  $where: uploads_productpage_has_image_bool_exp!, 
  $data: [uploads_productpage_has_image_insert_input!]!
) {
  delete_uploads_productpage_has_image(where: $where) {
    affected_rows
  }
  insert_uploads_productpage_has_image(
    objects: $data
  ) {
    returning {
      productpage_id
      image_id
    }
  }
}
`;
export const product_has_images = gql` 
mutation updateProductHasImages(
  $where: uploads_product_has_image_bool_exp!, 
  $data: [uploads_product_has_image_insert_input!]!
) {
  delete_uploads_product_has_image(where: $where) {
    affected_rows
  }
  insert_uploads_product_has_image(
    objects: $data
  ) {
    returning {
      product_id
      image_id
    }
  }
}
`;
export const mode_has_images = gql` 
mutation updateModeHasImages(
  $where: uploads_mode_has_image_bool_exp!, 
  $data: [uploads_mode_has_image_insert_input!]!
) {
  delete_uploads_mode_has_image(where: $where) {
    affected_rows
  }
  insert_uploads_mode_has_image(
    objects: $data
  ) {
    returning {
      mode_id
      image_id
    }
  }
}
`;
export const product_has_documents = gql` 
mutation updateProductHasDocuments(
  $where: uploads_product_has_document_bool_exp!, 
  $data: [uploads_product_has_document_insert_input!]!
) {
  delete_uploads_product_has_document(where: $where) {
    affected_rows
  }
  insert_uploads_product_has_document(
    objects: $data
  ) {
    returning {
      product_id
      document_id
    }
  }
}
`;