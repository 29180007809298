import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'trm-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.sass']
})
export class AuthComponent implements OnInit{
  
  constructor(private router: Router) {}
  
  ngOnInit(){
    // this.router.navigate(['/login']);
  }
}
