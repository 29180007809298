import { UsersService } from './users.service';
import { map } from 'rxjs/operators';
import { GqlService } from '../gql/service/gql.service';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthService {

    private isAuthenticated = false;

    constructor(
        private usersService: UsersService,
        private gqlService: GqlService
    ){
        const token = localStorage.getItem('token')
        if (token){
            this.gqlService.setToken(token)
            this.isAuthenticated = true
        }
    }

    login(email, password){
        // fake auth
        return this.usersService.findUser(email, password).pipe(map((res)=>{
            console.log(res)
            if(res && res.token){
                this.gqlService.setToken(res.token)
                localStorage.setItem('token', res.token)
                this.isAuthenticated = true
                return res.token
            }else{
                this.isAuthenticated = false
                return null
            }
        }))
    }

    logout(){
        localStorage.removeItem('token')
        this.gqlService.setToken(null)
        this.isAuthenticated = false;
    }

    isLoggedIn(){
        return this.isAuthenticated;
    }

}

