import gql from "graphql-tag";

import { Model } from "./model.interface";

const getMany = gql`
  query getWarehouses(
    $limit: Int
    $where: warehouse_bool_exp
    $order_by: [warehouse_order_by!]
    $offset: Int
  ) {
    warehouse(limit: $limit, where: $where, order_by: $order_by, offset: $offset) {
      id
      name
    }
    warehouse_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

const getOne = gql`
  query getWarehouse($id: bigint!) {
    warehouse_by_pk(id: $id) {
      id
      name
      city
      shipping_time
    }
  }
`;

const createOne = gql`
  mutation createWarehouse($data: [warehouse_insert_input!]!) {
    insert_warehouse(objects: $data) {
      returning {
        id
        name
      }
    }
  }
`;

const createMany = gql`
  mutation createWarehouses($data: [warehouse_insert_input!]!) {
    insert_warehouse(objects: $data) {
      returning {
        id
        name
      }
    }
  }
`;
const updateOne = gql`
  mutation updateWarehouse($id: bigint!, $data: warehouse_set_input!) {
    update_warehouse(_set: $data, where: { id: { _eq: $id } }) {
      returning {
        id
        name
      }
    }
  }
`;
const updateWithRelations = gql`
  mutation updateWarehouseWithRelations($where: warehouse_bool_exp!, $data: warehouse_set_input!) {
    update_warehouse(_set: $data, where: $where) {
      returning {
        id
        name
      }
    }
  }
`;
const deleteOne = gql`
  mutation deleteWarehouse($id: bigint!) {
    delete_warehouse(where: { id: { _eq: $id } }) {
      returning {
        id
        name
      }
    }
  }
`;
const deleteMany = gql`
  mutation deleteWarehouses($where: warehouse_bool_exp!) {
    delete_warehouse(where: $where) {
      returning {
        id
        name
      }
    }
  }
`;

export const Warehouse: Model = {
  getOne,
  getMany,
  createOne,
  createMany,
  updateOne,
  updateWithRelations,
  deleteOne,
  deleteMany
};
