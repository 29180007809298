import gql from "graphql-tag";

import { Model } from "./model.interface";

const getMany = gql`
  query getProducts(
    $limit: Int
    $where: product_bool_exp
    $order_by: [product_order_by!]
    $offset: Int
  ) {
    product(
      limit: $limit
      where: $where
      order_by: $order_by
      offset: $offset
    ) {
      id
      name
      layout
      price_range
      ismain
      createdat
      updatedat
    }
    product_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

const getOne = gql`
  query getProduct($id: bigint!) {
    product_by_pk(id: $id) {
      id
      name
      layout
      price_range
      ismain
      createdat
      updatedat
      productpage_has_products {
        productpage {
          id
          name
        }
      }
      product_has_modes {
        mode {
          id
          name
        }
      }
      product_has_documents {
        document {
          id
          filename
          description
        }
      }
      product_has_images {
        image {
          id
          name
          thumb
        }
      }
    }
  }
`;

const createOne = gql`
  mutation createProduct($data: [product_insert_input!]!) {
    insert_product(objects: $data) {
      returning {
        id
        name
        layout
        price_range
        ismain
        createdat
        updatedat
        productpage_has_products {
          productpage {
            id
            name
          }
        }
        product_has_modes {
          mode {
            id
            name
          }
        }
        product_has_documents {
          document {
            id
            filename
            description
          }
        }
        product_has_images {
          image {
            id
            name
            thumb
          }
        }
      }
    }
  }
`;

const createMany = gql`
  mutation createProducts($data: [product_insert_input!]!) {
    insert_product(objects: $data) {
      returning {
        id
        name
      }
    }
  }
`;
const updateOne = gql`
  mutation updateProduct($id: bigint!, $data: product_set_input!) {
    update_product(_set: $data, where: { id: { _eq: $id } }) {
      returning {
        id
        name
        layout
        price_range
        ismain
        createdat
        updatedat
        productpage_has_products {
          productpage {
            id
            name
          }
        }
        product_has_modes {
          mode {
            id
            name
          }
        }
        product_has_documents {
          document {
            id
            filename
            description
          }
        }
        product_has_images {
          image {
            id
            name
            thumb
          }
        }
      }
    }
  }
`;
const updateWithRelations = gql`
  mutation updateProductWithRelations(
    $where: product_bool_exp!
    $data: product_set_input!
  ) {
    update_product(_set: $data, where: $where) {
      returning {
        id
        name
      }
    }
  }
`;
const deleteOne = gql`
  mutation deleteProduct($id: bigint!) {
    delete_product(where: { id: { _eq: $id } }) {
      returning {
        id
        name
      }
    }
  }
`;
const deleteMany = gql`
  mutation deleteProducts($where: product_bool_exp!) {
    delete_product(where: $where) {
      returning {
        id
        name
      }
    }
  }
`;

export const Product: Model = {
  getOne,
  getMany,
  createOne,
  createMany,
  updateOne,
  updateWithRelations,
  deleteOne,
  deleteMany
};
