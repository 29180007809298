export class ProductPage {

    static getConfiguration(): object{
        return {
            "item_type": "productPages",
            "item_title": "Страницы товаров",
            "item_model": "ProductPage",
            "hiddens": ["id"],
            "hiddens_obj": {
                "id": 'ID'
            },
            "inputs": ["name", "acronym", "name_key"],
            "inputs_obj": {
                "name": 'Имя',
                "acronym": 'Аббревиатура',
                "name_key": 'Именной идентификатор'
            },
            "textareas": ["prev_descr", "descr", "description", "specification", "meta_descr", "meta_keywords"],
            "textareas_obj": {
                "description": 'Описание',
                "descr": 'Короткое описание',
                "prev_descr": 'Предварительное описание',
                "specification": 'Тех. характеристики',
                "meta_descr": 'Meta-Description',
                "meta_keywords": 'Meta-Keywords'
            },
            "labels": ["updatedat","createdat","rating"],
            "labels_obj": {
                "updatedat": 'Обновлено',
                "createdat": 'Создано',
                "rating": 'Посещаемость'
            },
            "switches": ["visibility", "instock", "isautoupdating"],
            "switches_obj": {
                "visibility": 'Видимость',
                "instock": 'В продаже',
                "isautoupdating": 'Автообновление'
            },
            "switches_default": {
                "visibility": 1,
                "instock": true,
                "isautoupdating": true
            },
            "images": [
                {
                    "name": "Изображения",
                    "folder": 'store',
                    "rest": "productPageImages",
                    "model": "ProductPageImage",
                    "item_col": "productpage_has_images",
                    "set_output": (id1, id2) => ({productpage_id: id1, image_id: id2})
                }
            ],
            "relations_info": [
                {
                    "name": "Родительские категории",
                    "rest": "categories",
                    "model": "Category",
                    "item_col": "category_has_productpages",
                    // "free_type": "category_has_productpages",
                    "set_output": (id1, id2) => ({productpage_id: id1, category_id: id2})
                },
                {
                    "name": "Товары",
                    "rest": "products",
                    "model": "Product",
                    "item_col": "productpage_has_products",
                    // "free_type": "productpage_has_products",
                    "set_output": (id1, id2) => ({productpage_id: id1, product_id: id2})
                },
                // {
                //     "name": "Решения по автоматизации",
                //     "rest": "automations",
                //     "model": "ArticleAutomation",
                //     "item_col": "articleAutomation_ids",
                //     "item_col_json": "articleAutomation_ids_json"
                // }
            ],
            // "filters": ["visibilityOff", "visibilityOn", "inStockOff", "inStockOn", "prevDescrEmpty"],
            // "filters_obj": {
            //     "visibilityOff":{
            //         "label": "Скрытые",
            //         "paramName": '&filter[visibility]',
            //         "paramValues": [0]
            //     },
            //     "visibilityOn":{
            //         "label": "Видимые",
            //         "paramName": '&filter[visibility]',
            //         "paramValues": [1]
            //     },
            //     "inStockOff":{
            //         "label": "Снято с продажи",
            //         "paramName": '&filter[inStock]',
            //         "paramValues": [0]
            //     },
            //     "inStockOn":{
            //         "label": "В продаже",
            //         "paramName": '&filter[inStock]',
            //         "paramValues": [1]
            //     },
            //     "prevDescrEmpty": {
            //         "label": "C пустым описанием",
            //         "paramName": '&filter[prev_descr]',
            //         "paramValues": ['']
            //     }
            // }
        }
    }

}