import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { RegistrationComponent } from "./registration/registration.component";
import { AuthComponent } from "./auth.component";

const routes: Routes = [
    {path: 'auth', component: AuthComponent, children:[
        {path: 'login', component: LoginComponent},
        {path: 'registration', component: RegistrationComponent}
    ]}
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule]
})
export class AuthRoutingModel {}
