import gql from "graphql-tag";

import { Model } from "./model.interface";

const getMany = gql`
  query getCategories(
    $limit: Int
    $where: category_bool_exp
    $order_by: [category_order_by!]
    $offset: Int
  ) {
    category(
      limit: $limit
      where: $where
      order_by: $order_by
      offset: $offset
    ) {
      id
      name
      visibility
      rating
      createdat
      updatedat
      # categoryHasCategoriesByParentId {
      #   categoryByDaughterId {
      #     id
      #     name
      #   }
      # }
    }
    category_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

const getOne = gql`
  query getCategory($id: bigint!) {
    category_by_pk(id: $id) {
      id
      name
      full_name
      description
      meta_descr
      meta_keywords
      visibility
      rating
      createdat
      updatedat
      category_has_categories {
        categoryByParentId {
          id
          name
        }
      }
      categoryHasCategoriesByParentId {
        category {
          id
          name
        }
      }
      category_has_productpages {
        productpage {
          id
          name
        }
      }
      category_has_images {
        image {
          id
          name
          thumb
        }
      }
    }
  }
`;

const createOne = gql`
  mutation createCategory($data: [category_insert_input!]!) {
    insert_category(objects: $data) {
      returning {
        id
        name
        full_name
        description
        meta_descr
        meta_keywords
        visibility
        rating
        createdat
        updatedat
        category_has_categories {
          categoryByParentId {
            id
            name
          }
        }
        categoryHasCategoriesByParentId {
          category {
            id
            name
          }
        }
        category_has_productpages {
          productpage {
            id
            name
          }
        }
        category_has_images {
          image {
            id
            name
            thumb
          }
        }
      }
    }
  }
`;

const createMany = gql`
  mutation createCategories($data: [category_insert_input!]!) {
    insert_category(objects: $data) {
      returning {
        id
        name
      }
    }
  }
`;
const updateOne = gql`
  mutation updateCategory($id: bigint!, $data: category_set_input!) {
    update_category(_set: $data, where: { id: { _eq: $id } }) {
      returning {
        id
        name
        full_name
        description
        meta_descr
        meta_keywords
        visibility
        rating
        createdat
        updatedat
        category_has_categories {
          categoryByParentId {
            id
            name
          }
        }
        categoryHasCategoriesByParentId {
          category {
            id
            name
          }
        }
        category_has_productpages {
          productpage {
            id
            name
          }
        }
        category_has_images {
          image {
            id
            name
            thumb
          }
        }
      }
    }
  }
`;
const updateWithRelations = gql`
  mutation updateCategoryWithRelations(
    $where: category_bool_exp!
    $data: category_set_input!
  ) {
    update_category(_set: $data, where: $where) {
      returning {
        id
        name
      }
    }
  }
`;
const deleteOne = gql`
  mutation deleteCategory($id: bigint!) {
    delete_category(where: { id: { _eq: $id } }) {
      returning {
        id
        name
      }
    }
  }
`;
const deleteMany = gql`
  mutation deleteCategories($where: category_bool_exp!) {
    delete_category(where: $where) {
      returning {
        id
        name
      }
    }
  }
`;

export const Category: Model = {
  getOne,
  getMany,
  createOne,
  createMany,
  updateOne,
  updateWithRelations,
  deleteOne,
  deleteMany
};
