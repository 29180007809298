export class News {
  constructor(
   
  ){}

  static getConfiguration(): object{
      return {
          "item_type": "news",
          "item_title": "Новости",
          "item_model": "ArticleNews",
          "inputs": ["title", "id", "img"],
          "inputs_obj": {
            "id": 'ID',
            "title": 'Заголовок',
            "img": 'Изображение'
          },
          "textareas": ["prev_descr", 'body'],
          "textareas_obj": {
            "prev_descr": 'Описание',
            "body": 'Текст'
          },
          "switches": ["visibility"],
          "switches_obj": {
            "visibility": 'Доступ',
          },
          "switches_default": {
            "visibility": 1
          },
          "labels": ["rating", "createdAt", "updatedAt"],
          "labels_obj": {
            "rating": 'Посещаемость',
            "createdAt": 'Создано',
            "updatedAt": 'Обновлено'
          },
          "relations": ["productPage_ids"],
          "relations_info": [
              {
                  "name": "Товары",
                  "rest": "productPages",
                  "model": "ProductPage",
                  "item_col": "productPage_ids",
                  "item_col_json": "productPage_ids_json"
              }
          ]
      }
  }

}