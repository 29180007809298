<div class="main-wrapper">
  <div class="app">
    <!--HEADER-->
    <!-- <header class="header">
      <div class="header-block header-block-search">
        12:12:12
      </div>
      <div class="header-block header-block-nav">
        <ul class="nav-profile">
          <li class="profile dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button">
              <span class="name">
                Здравствуйте, Ирина
              </span>
            </a>
            <div class="dropdown-menu profile-dropdown-menu">
              <a class="dropdown-item" href="#">
                <i class="fa fa-gear icon"></i>Сделать запись</a
              >
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">
                <i class="fa fa-power-off icon"></i>Выйти</a
              >
            </div>
          </li>
        </ul>
      </div>
    </header> -->
    <!--HEADER-->
    <aside class="sidebar">
      <div class="sidebar-container">
        <div class="sidebar-header">
          <div class="brand">
            <img src="/assets/img/logo.png" alt="" />
            <!-- <div class="logo">
              <span class="l l1"></span>
              <span class="l l2"></span>
              <span class="l l3"></span>
              <span class="l l4"></span>
              <span class="l l5"></span>
            </div> -->
            <!-- Бухгалтерия -->
          </div>
        </div>
        <nav class="menu">
          <ul class="nav metismenu">
            <!-- <li routerLinkActive="active">
              <a [routerLink]="['/system','orders']">
                <i class="fa fa-building-o"></i> Заказы </a>
              <ul>
                <li routerLinkActive="active"><a [routerLink]="['/system','orders','all']"><i class="fa fa-archive"></i> Все </a></li>
                <li routerLinkActive="active"><a [routerLink]="['/system','orders','active']"><i class="fa fa-archive"></i> Активные </a></li>
                <li routerLinkActive="active"><a [routerLink]="['/system','orders','done']"><i class="fa fa-plus-square"></i> Законченные </a></li>
                <li routerLinkActive="active"><a [routerLink]="['/system','orders','suppliers']"><i class="fa fa-flash"></i> Поставщики </a></li>
                <li routerLinkActive="active"><a [routerLink]="['/system','orders','offer']"><i class="fa fa-flash"></i> Выставить счет </a></li>
                <li routerLinkActive="active"><a [routerLink]="['/system','orders','job']"><i class="fa fa-flash"></i> Заказ </a></li>
              </ul>
            </li> -->
            <li routerLinkActive="active">
              <a [routerLink]="['/system', 'cms', 'categories']">
                <i class="fa fa-plus-square"></i>Управление товарами</a
              >
              <ul>
                <li routerLinkActive="active">
                  <a [routerLink]="['/system', 'cms', 'categories']"
                    ><i class="fa fa-circle"></i> Категории
                  </a>
                </li>
                <li routerLinkActive="active">
                  <a [routerLink]="['/system', 'cms', 'productPages']"
                    ><i class="fa fa-circle"></i> Страницы товаров
                  </a>
                </li>
                <li routerLinkActive="active">
                  <a [routerLink]="['/system', 'cms', 'products']"
                    ><i class="fa fa-circle"></i> Товары
                  </a>
                </li>
                <li routerLinkActive="active">
                  <a [routerLink]="['/system', 'cms', 'modes']"
                    ><i class="fa fa-circle"></i> Модификации
                  </a>
                </li>
                <li routerLinkActive="active">
                  <a [routerLink]="['/system', 'cms', 'filters']"
                    ><i class="fa fa-circle"></i> Фильтры</a
                  >
                </li>
                <li routerLinkActive="active">
                  <a [routerLink]="['/system', 'cms', 'parameters']"
                    ><i class="fa fa-circle"></i> Параметры
                  </a>
                </li>
                <li routerLinkActive="active">
                  <a [routerLink]="['/system', 'cms', 'articles']"
                    ><i class="fa fa-circle"></i> Статьи
                  </a>
                </li>
                <li routerLinkActive="active">
                  <a [routerLink]="['/system', 'cms', 'news']"
                    ><i class="fa fa-circle"></i> Новости
                  </a>
                </li>
                <li routerLinkActive="active">
                  <a [routerLink]="['/system', 'cms', 'automations']"
                    ><i class="fa fa-circle"></i> Решения по автоматизации
                  </a>
                </li>
              </ul>
            </li>
            <li routerLinkActive="active">
              <a [routerLink]="['/system', 'import']">
                <i class="fa fa-plus-square"></i>Импорт данных</a
              >
              <ul>
                <li routerLinkActive="active">
                  <a [routerLink]="['/system', 'import', 'price']"
                    ><i class="fa fa-circle"></i> Импорт цен
                  </a>
                </li>
                <li routerLinkActive="active">
                  <a [routerLink]="['/system', 'import', 'warehouse']"
                    ><i class="fa fa-circle"></i> Импорт склада
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
        <div class="logout">
          <button (click)="onLogout()">Выйти</button>
        </div>
      </div>
    </aside>
    <article class="content dashboard-page">
      <router-outlet></router-outlet>
    </article>
  </div>
</div>
