export class Mode {

    static getConfiguration(): object{
        return {
            "item_type": "modes",
            "item_title": "Модификации",
            "item_model": "Mode",
            "hiddens": ["id"],
            "hiddens_obj": {
                "id": 'ID'
            },
            "inputs": ["name", "modification", "full_name", "guid"],
            "inputs_obj": {
                "name": 'Имя',
                "modification": 'Мод.',
                "full_name": 'Полное имя',
                "guid": 'GUID'
            },
            "textareas": ["description", "meta_descr", "meta_keywords"],
            "textareas_obj": {
                "description": 'Описание',
                "meta_descr": 'meta_descr',
                "meta_keywords": 'meta_keywords'
            },
            "switches": ["visibility", "isstorageposition"],
            "switches_obj": {
                "visibility": 'Доступ',
                "isstorageposition": 'Складская поз.'
            },
            "switches_default": {
                "visibility": 1,
                "isstorageposition": 0                
            },
            "labels": ["updatedat","createdat"],
            "labels_obj": {
                "updatedat": 'Обновлено',
                "createdat": 'Создано'
            },
            "images": [
                {
                    "name": "Изображения",
                    "folder": 'store',
                    "rest": "modeImages",
                    "model": "ModeImage",
                    "item_col": "mode_has_images",
                    "set_output": (id1, id2) => ({mode_id: id1, image_id: id2})
                }
            ],
            "relations_info": [
                {
                    "name": "Товары",
                    "rest": "products",
                    "model": "Product",
                    "item_col": "product_has_modes",
                    "set_output": (id1, id2) => ({mode_id: id1, product_id: id2})
                },
                // {
                //     "name": "Параметры",
                //     "rest": "parameters",
                //     "model": "Parameter",
                //     "item_col": "parameter_ids",
                //     "item_col_json": "parameter_ids_json"
                // },
                {
                    "name": "Склады",
                    "rest": "warehouses",
                    "model": "Warehouse",
                    "item_col": "warehouse_has_modes",
                    "set_output": (id1, id2) => ({mode_id: id1, warehouse_id: id2})
                }
            ],
            "prices_info": [
                {
                    "name": "Цены и сроки",
                    "rest": 'warehouseHasModes',
                    "model": "WarehouseHasMode"
                }
            ],
            // "filters": ["visibilityOff", "prevDescrEmpty"],
            // "filters_obj": {
            //     "visibilityOff":{
            //         "label": "Скрытые",
            //         "paramName": '&filter[visibility]',
            //         "paramValues": [0]
            //     },
            //     "isStoragePositionOff":{
            //         "label": "Заказные позиции",
            //         "paramName": '&filter[isstorageposition]',
            //         "paramValues": [0]
            //     },
            //     "isStoragePositionOn":{
            //         "label": "Складские позиции",
            //         "paramName": '&filter[isstorageposition]',
            //         "paramValues": [1]
            //     },
            //     "prevDescrEmpty": {
            //         "label": "C пустым описанием",
            //         "paramName": '&filter[description]',
            //         "paramValues": ['']
            //     }
            // }
        }
    }

}