<div>
  <trm-import-catalog
    *ngIf="items_type === 'catalog'"
    (output)="onSubmit($event)"
  >
  </trm-import-catalog>
  <trm-import-warehouse
    *ngIf="items_type === 'price' || items_type === 'warehouse'"
    (output)="onSubmit($event)"
  >
  </trm-import-warehouse>
  <div class="results">
    <div>
      <div *ngFor="let item of results">
        <div style="display: flex;">
          <p>{{ item.data.name }}</p>
          <p>{{ item.status }}</p>
        </div>
        <div>
          <div>
            <!-- <p>{{ item.images.status }}</p> -->
            <p *ngFor="let image of item.data.images">{{image.link}}</p>
          </div>
          <div>
            <!-- <p>{{ item.products.status }}</p> -->
            <div *ngFor="let product of item.data.products">
              <p>{{product.name}}</p>
              <div *ngFor="let mode of product.modes">
                <p>{{mode.name}}</p>
              </div>
              <div *ngFor="let document of product.documents">
                <p>{{document.filename}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <button>Подтвердить</button> -->
  </div>
</div>
