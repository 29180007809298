export class Parameter {

    static getConfiguration(): object{
        return {
            "item_type": "parameters",
            "item_title": "Параметры",
            "item_model": "Parameter",
            "hiddens": ["id"],
            "hiddens_obj": {
                "id": 'ID'
            },
            "inputs": ["name", "value", "Measure_id"],
            "inputs_obj": {
                "name": 'Имя',
                "value": 'Значение',
                "Measure_id": 'Физ. величина'
            },
            "labels": [],
            "labels_obj": {},
            "relations_info": [
                {
                    "name": "Фильтры",
                    "rest": "filters",
                    "model": "Filter",
                    "item_col": "filter_ids",
                    "item_col_json": "filter_ids_json"
                },
                {
                    "name": "Модификации",
                    "rest": "modes",
                    "model": "Mode",
                    "item_col": "mode_ids",
                    "item_col_json": "mode_ids_json"
                },
                {
                    "name": "Товары",
                    "rest": "products",
                    "model": "Product",
                    "item_col": "product_ids",
                    "item_col_json": "product_ids_json"
                }
            ]
        }
    }

}