<div>
  <h4>Импорт каталога</h4>
  <form action="">
    <input type="text" placeholder="Ссылка" />
    <button (click)="onSubmit()">Начать импорт</button>
  </form>
  <div class="results" *ngIf="results.length > 0">
    <h1>Тут будут предварительные результаты</h1>
    <button>Подтвердить</button>
  </div>
</div>