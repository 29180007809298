import { of } from 'rxjs';

export class UsersService {
  private users = [
    {
      email: "termonika@yandex.ru",
      password: "Termonika2019@",
      token: "TermonikaSecretKey"
    }
  ];

  findUser(email, password) {
    return of(this.users.find(u => u.email === email && u.password === password))
  }
}
