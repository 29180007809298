import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SystemComponent } from "./system.component";
import { CmsPageComponent } from './cms/page/cms-page.component';
import { ImportPageComponent } from './import/page/import-page.component';

const routes: Routes = [
    {path: 'system', component: SystemComponent, children: [
        {path: 'cms', component: CmsPageComponent, children: [
            {path: 'products', component: CmsPageComponent},
            {path: 'categories', component: CmsPageComponent},
            {path: 'productPages', component: CmsPageComponent},
            {path: 'modes', component: CmsPageComponent},
            {path: 'filters', component: CmsPageComponent},
            {path: 'parameters', component: CmsPageComponent},
            {path: 'articles', component: CmsPageComponent},
            {path: 'news', component: CmsPageComponent},
            {path: 'automations', component: CmsPageComponent}
        ]},
        {path: 'import', component: ImportPageComponent, children: [
            {path: 'catalog', component: ImportPageComponent},
            {path: 'price', component: ImportPageComponent},
            {path: 'warehouse', component: ImportPageComponent},
        ]}
    ]},
    // { path: '**', component: SystemComponent }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SystemRoutingModule {}