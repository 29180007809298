import {
  Component,
  OnInit,
  Input,
  HostListener,
  Inject,
  ElementRef,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
  OnChanges,
  SimpleChanges,
  AfterViewInit
} from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Observable } from "rxjs/Rx";
import "rxjs/add/observable/fromEvent";
import { ItemComponent } from "./item/item.component";
import { ProductsService } from "src/app/shared/services/products.service";
import {GqlService} from 'src/app/shared/gql/service/gql.service'
import { HttpResponse } from "@angular/common/http";
import { TableComponent } from "./components/table/table.component";
// import { keyframes } from '../../../../node_modules/@angular/animations/src/animation/dsl';

import { ConfirmationService } from "primeng/api";

@Component({
  selector: "trm-items-list",
  templateUrl: "./items-list.component.html",
  styleUrls: ["./items-list.component.sass"]
})
export class ItemsListComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() items_type: string;

  @Input() action: string = "list";

  items: any[] = [];
  // items
  relations: string[] = [];

  configuration: any;

  page: number = 1;
  searchQuery: string = "";
  sortOptions: any[] = [];
  sortBy: string = "-updatedAt";
  end_list_flag = false;

  columns = [];

  public isFixed: boolean = false;
  // eventSubscription: any

  @ViewChild("itemContainer", { read: ViewContainerRef }) container;
  // componentRef: ComponentRef;
  componentRef;

  // @ViewChild('itemsTable') tableContainer: ElementRef;
  @ViewChild("itemsTable", { read: ViewContainerRef }) tableContainer;
  // componentRef: ComponentRef;
  tableComponentRef;

  constructor(
    private productsService: ProductsService,
    private gqlService: GqlService,
    private resolver: ComponentFactoryResolver,
    // @Inject (DOCUMENT) private document: Document,
    // @ViewChild('target') private targetElement: ElementRef
    private confirmationService: ConfirmationService
  ) {
    // объявление конфигурации
    // this.configObject = {
    //   settings: [],
    //   fields: [],
    //   data: []
    // }
  }

  ngOnInit() {
    // console.log(this.tableContainer);
    // this.configuration = this.productsService.getModel(this.items_type);
  }

  ngAfterViewInit() {
    // console.log(this.tableContainer);
    this.onItemsTypeChanged();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {
      let chng = changes[propName];
      let cur = JSON.stringify(chng.currentValue);
      let prev = JSON.stringify(chng.previousValue);
      console.log(propName + " changes: " + prev + " -> " + cur);
      if (propName === "items_type" && prev !== undefined) {
        this.onItemsTypeChanged();
      }
    }
  }

  onItemsTypeChanged() {
    this.configuration = this.productsService.getModel(this.items_type);
    // console.log(this.configuration)

    this.sortOptions = this.configuration.sort_options || [];

    const {
      hiddens = [],
      inputs = [],
      switches = [],
      labels = []
    } = this.configuration;
    const {
      hiddens_obj = {},
      inputs_obj = {},
      switches_obj = {},
      labels_obj = {}
    } = this.configuration;

    const main_columns = hiddens
      .concat(inputs)
      .concat(switches)
      .concat(labels);
    const main_fields = {
      ...hiddens_obj,
      ...inputs_obj,
      ...switches_obj,
      ...labels_obj
    };

    let settings = main_columns.map((col, i) => {
      let sort = "enabled";
      // if(i===0) sort = 'asc'
      return {
        objectKey: col,
        sort: sort,
        sortOrder: i,
        columnOrder: i
        // visible: true
      };
    });

    let fields = main_columns.map(col => {
      return {
        objectKey: col,
        name: main_fields[col]
      };
    });
    this.columns = main_columns.map(col => {
      return {
        prop: col,
        name: main_fields[col]
      };
    });
    settings[0].sort = "asc";
    settings.push(
      {
        objectKey: "edit_action",
        sort: "disable",
        search: false
      },
      {
        objectKey: "delete_action",
        sort: "disable",
        search: false
      }
    );
    fields.push(
      {
        name: "",
        columnClass: "gt-button",
        objectKey: "delete_action",
        value: () => "",
        columnComponent: {}
      },
      {
        name: "",
        columnClass: "gt-button",
        objectKey: "edit_action",
        value: () => "",
        columnComponent: {}
      }
    );

    this.items = [];
    this.page = 1;
    this.sortBy = "-updatedAt";
    this.searchQuery = "";
    // this.onNextPage();

    if (this.componentRef) this.componentRef.destroy();

    if (this.tableComponentRef) this.tableComponentRef.destroy();

    this.createTable();

    // this.productsService.getRelations(this.configuration.relations_info)
    //   .subscribe((relations: any[]) => {
    //     this.relations = relations
    //   })
  }

  // onSearchRequest = async ()

  getRows = async ({
    page = 0,
    perPage = 50,
    sorts = [],
    search = "",
    filters = []
  }) => {
    // console.log(filters, sorts)
    return new Promise((res, rej) => {
      this.gqlService.getItems({
        type: this.items_type, 
        page, 
        sort: sorts.map(s=>({[s.prop]: s.dir})), 
        qty: perPage, 
        search, 
        filters: undefined 
      })
      .subscribe(({data, count, pageInfo, isLastPage}) => {
        res({rows: data, pageInfo})
      })
    });
  };

  onAddItem(action: string) {
    console.log("Need to " + action + " new item");
    this.createComponent(action, 0, "");
  }

  onItemAction = (item_id: any, action: string): Promise<any> => {
    console.log("Let's " + action + " item " + item_id);
    switch (action) {
      case "create":
        return this.createComponent(action, 0, "");
      case "open":
        return this.createComponent(action, 0, item_id);
      case "copy":
        return this.createComponent(action, 0, item_id);
      case "delete":
        return this.onRemove(item_id);
    }
  };

  onRemove(item_id: any) {
    console.log("remove confirming");
    return new Promise((res, rej) => {
      this.confirmationService.confirm({
        message: "Уверены?",
        accept: () => {
          console.log("remove confirmed");
          this.gqlService.deleteItem(item_id, this.items_type).subscribe((response: any)=>{
            console.log(response)
            this.items.forEach((item, key) => {
              if (item.id === response.id) {
                this.items.splice(key, 1);
              }
            });
            res(true);
          })
          // let item = this.productsService.createOnlyEmptyJsonColumns(
          //   this.configuration
          // );
          // this.productsService
          //   .updateItem(item, this.items_type, item_id)
          //   .subscribe((res_item: any) => {
          //     this.productsService
          //       .deleteItem(item_id, this.items_type)
          //       .subscribe((item: any) => {
          //         this.items.forEach((item, key) => {
          //           if (item.id == item_id) {
          //             this.items.splice(key, 1);
          //           }
          //         });
          //         res(true);
          //       });
          //   });
        },
        reject: () => {
          console.log("remove canceled");
          res(false);
        }
      });
    });
  }

  createTable() {
    // console.log(this.tableContainer);
    if (!this.tableContainer) return;
    this.tableContainer.clear();
    const factory = this.resolver.resolveComponentFactory(TableComponent);
    this.tableComponentRef = this.tableContainer.createComponent(factory);
    this.tableComponentRef.instance.configuration = this.configuration;
    this.tableComponentRef.instance.columns = this.columns;
    this.tableComponentRef.instance.getRows = this.getRows;
    this.tableComponentRef.instance.onAction = this.onItemAction;
  }

  createComponent(type, seq, item_id): Promise<any> {
    return new Promise((res, rej) => {
      this.container.clear();
      const factory = this.resolver.resolveComponentFactory(ItemComponent);
      this.componentRef = this.container.createComponent(factory);
      this.componentRef.instance.type = type;
      this.componentRef.instance.seq = seq;
      this.componentRef.instance.item_id = item_id;
      this.componentRef.instance.configuration = this.configuration;
      this.componentRef.instance.item_type = this.items_type;
      this.componentRef.instance.relations = this.relations;
      this.componentRef.instance.output.subscribe(event => {
        console.log(event);

        if (event.status === "success") {
          if (event.type === "create") {
            this.items = [event.data, ...this.items];
            res(event.data);
            // console.log(this.items)
          }
          if (event.type === "update") {
            // console.log(event.data)
            this.items = this.items.map(item => {
              if (item.id == event.data.id) {
                return event.data;
              } else {
                return item;
              }
            });
            res(event.data);
          }
          if (event.type === "cancel") {
            res(false);
          }
          this.componentRef.destroy();
        } else {
          res(false);
        }
      });
    });
  }

  // onRemoveMany(){

  // }
  // onAction(){
  //   // действия в верхней части панели
  // }
  // scrollTo(item_id: string){
  //   //
  // }
}
