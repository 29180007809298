<div class="relation_wrap">
  <div class="relation_title">
    <h4>{{ info.name }}</h4>
  </div>
  <div class="relation_wrap_content">
    <div class="end_values" *ngIf="activeItems.length > 0">
      <div class="relation_title">
        <h4>Активные значения</h4>
        <button (click)="isTotalOpened=!isTotalOpened"><i class="fa {{ (isTotalOpened) ? 'fa-angle-up' : 'fa-angle-down' }} fa-2x"></i></button>
      </div>
      <div *ngIf="isTotalOpened">
        <div *ngIf="activeItems.length > 10">
          <input type="text" placeholder="Поиск" (keyup)="onSearchForActive($event.target.value)">
        </div>
        <ngx-datatable
          class="dark"
          [rows]="filteredActiveItems"
          [columnMode]="'force'"
          [headerHeight]="50"
          [footerHeight]="(filteredActiveItems.length > 10) ? 100 : false"
          [rowHeight]="'auto'"
          [loadingIndicator]="loading"
          [limit]="10"
        >
          <ngx-datatable-column prop="name" name="Имя">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row" let-value="value">
              <label class="input-container">{{ value }}
                <input type="checkbox" value="{{ row.id }}" [checked]="row.isChecked" (change)="onChange($event)">
                <span class="checkmark"></span>
              </label>
            </ng-template>
          </ngx-datatable-column>
    
          <ngx-datatable-column prop="id" name="" [width]="100" [sortable]="false" [canAutoResize]="false" [draggable]="false" [resizeable]="false">
            <ng-template ngx-datatable-cell-template let-value="value" >
              <button (click)="onItemAction(value, 'open')"><i class="fa fa-edit"></i></button>
              <!-- <button (click)="onItemAction(item.id, 'copy')"><i class="fa fa-copy"></i></button> -->
              <!-- <button (click)="onItemAction(item.id, 'delete')"><i class="fa fa-trash"></i></button> -->
            </ng-template>
          </ngx-datatable-column>
      
        </ngx-datatable>
      </div>
    </div>
    <div class="values">
      <div class="relation_title">
        <h4>Все значения</h4>
        <button (click)="isOpened=!isOpened"><i class="fa {{ (isOpened) ? 'fa-angle-up' : 'fa-angle-down' }} fa-2x"></i></button>
        <div>
          <button (click)="onAddItem('create')">
            <i class="fa fa-plus"></i>
          </button>
        </div>
        <div class="searchbar" *ngIf="isOpened">
          <div>
            <input type="text" placeholder="Поиск" (keyup)="onSearch($event.target.value)">
          </div>
        </div>
      </div>
      <div *ngIf="isOpened" class="values-list">
        <div *ngFor="let item of items; let i = index">
          <div class="input-checkbox">
            <label class="input-container">{{item.name || item.title}}
              <input type="checkbox" value="{{ item.id }}" [checked]="item.isChecked" (change)="onChange($event)">
              <span class="checkmark"></span>
            </label>
            <div>
              <button (click)="onItemAction(item.id, 'open')"><i class="fa fa-edit"></i></button>
              <!-- <button (click)="onItemAction(item.id, 'copy')"><i class="fa fa-copy"></i></button> -->
              <!-- <button (click)="onItemAction(item.id, 'delete')"><i class="fa fa-trash"></i></button> -->
            </div>
          </div>
        </div>
        <div>
          <button *ngIf="!end_list_flag" (click)="onNextPage()">Больше</button>
        </div>
      </div>
      
    </div>
    <ng-template #item2Container></ng-template>
  </div>
</div>