import gql from "graphql-tag";

import { Model } from "./model.interface";

const getMany = gql`
  query getProductPages(
    $limit: Int
    $where: productpage_bool_exp
    $order_by: [productpage_order_by!]
    $offset: Int
  ) {
    productpage(
      limit: $limit
      where: $where
      order_by: $order_by
      offset: $offset
    ) {
      id
      name
      acronym
      parse_link
      name_key
      visibility
      rating
      isautoupdating
      instock
      createdat
      updatedat
    }
    productpage_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

const getOne = gql`
  query getProductPage($id: bigint!) {
    productpage_by_pk(id: $id) {
      id
      name
      acronym
      parse_link
      name_key
      visibility
      rating
      isautoupdating
      instock
      prev_descr
      descr
      description
      specification
      meta_descr
      meta_keywords
      createdat
      updatedat
      category_has_productpages {
        # category_id
        category {
          id
          name
        }
      }
      productpage_has_products {
        product {
          id
          name
        }
      }
      productpage_has_images {
        image {
          id
          name
          thumb
        }
      }
    }
  }
`;

const createOne = gql`
  mutation createProductPage($data: [productpage_insert_input!]!) {
    insert_productpage(objects: $data) {
      returning {
        id
        name
        acronym
        parse_link
        name_key
        visibility
        rating
        isautoupdating
        instock
        prev_descr
        descr
        description
        specification
        meta_descr
        meta_keywords
        createdat
        updatedat
        category_has_productpages {
          # category_id
          category {
            id
            name
          }
        }
        productpage_has_products {
          product {
            id
            name
          }
        }
        productpage_has_images {
          image {
            id
            name
            thumb
          }
        }
      }
    }
  }
`;

const createMany = gql`
  mutation createProductPages($data: [productpage_insert_input!]!) {
    insert_productpage(objects: $data) {
      returning {
        id
        name
      }
    }
  }
`;
const updateOne = gql`
  mutation updateProductPage($id: bigint!, $data: productpage_set_input!) {
    update_productpage(_set: $data, where: { id: { _eq: $id } }) {
      returning {
        id
        name
        acronym
        parse_link
        name_key
        visibility
        rating
        isautoupdating
        instock
        prev_descr
        descr
        description
        specification
        meta_descr
        meta_keywords
        createdat
        updatedat
        category_has_productpages {
          # category_id
          category {
            id
            name
          }
        }
        productpage_has_products {
          product {
            id
            name
          }
        }
        productpage_has_images {
          image {
            id
            name
            thumb
          }
        }
      }
    }
  }
`;
const updateWithRelations = gql`
  mutation updateProductPageWithRelations(
    $where: productpage_bool_exp!
    $data: productpage_set_input!
  ) {
    update_productpage(_set: $data, where: $where) {
      returning {
        id
        name
      }
    }
  }
`;
const deleteOne = gql`
  mutation deleteProductPage($id: bigint!) {
    delete_productpage(where: { id: { _eq: $id } }) {
      returning {
        id
        name
      }
    }
  }
`;
const deleteMany = gql`
  mutation deleteProductPages($where: productpage_bool_exp!) {
    delete_productpage(where: $where) {
      returning {
        id
        name
      }
    }
  }
`;

export const ProductPage: Model = {
  getOne,
  getMany,
  createOne,
  createMany,
  updateOne,
  updateWithRelations,
  deleteOne,
  deleteMany
};
