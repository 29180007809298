import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'trm-file-wrap',
  templateUrl: './file-wrap.component.html',
  styleUrls: ['./file-wrap.component.sass']
})
export class FileWrapComponent implements OnInit {

  @Input() file: any

  @Output() fileChange = new EventEmitter<any>();
  // onFileChange(){
  //   this.fileChange.emit(this.file);
  // }
  
  constructor() { }

  ngOnInit() {
  }

  onInputChange(event: any){
    this.file.description = event.target.value
    this.fileChange.emit([this.file,"title"]);
  }

  onDelete(){
    this.fileChange.emit([this.file,"delete"]);
  }

}
