import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ProductsService } from 'src/app/shared/services/products.service';
import { GqlService } from 'src/app/shared/gql/service/gql.service';

@Component({
  selector: 'trm-files-wrap',
  templateUrl: './files-wrap.component.html',
  styleUrls: ['./files-wrap.component.sass']
})
export class FilesWrapComponent implements OnInit, OnChanges {

  @Input() file_ids = []
  @Input() info
  @Output() fileChange = new EventEmitter<any>()
  files_list = []
  count: number
  max: number

  constructor(
    // private productsService: ProductsService,
    private readonly gqlService: GqlService
  ) { }

  ngOnInit(){

  }

  ngOnChanges() {

    console.log("Files. OnChanges");
    // преобразуем пришедшие данные
    this.files_list = this.file_ids.map(v => Object.values(v)[0]);
    this.max = 50;
    // this.constructFilesList()

  }

  // constructFilesList(withLoading = true){
  //   if(!this.file_ids || this.file_ids.length == 0){
  //     this.files_list = []
  //   }else{
  //     // if(withLoading){
  //     this.files_list = this.file_ids.map(v=>Object.values(v)[0])
  //     // }
  //   }
    
  // }

  onAdd(){
    
  }

  onFilesChosen(event: any){

    let files = Array.from(event.target.files)
    event.target.value = ""

    this.gqlService.uploadFiles(files, 'document').subscribe((res)=>{
      // console.log(res)
      // if(res)
      this.files_list = [...this.files_list, ...res]
      this.fileChange.emit([this.files_list, this.info]);
    })

  }

  onFileChanged(file){
    // console.log(file)
    // console.log(this.item_info)

    switch(file[1]){
      case 'delete':
        this.files_list = this.files_list.filter((f) => {
          return !(f.id === file[0].id)
        })
        // this.files_list = this.file_ids
        // if(this.file_ids.length == 0){/* this.images = undefined*/}
        // this.constructFilesList(false)
        break
      case 'title':
        console.log("Title is changed for: " + file[0].description)
        this.gqlService.updateItem(file[0], 'documents', file[0].id).subscribe((res)=>{
          console.log(res)
        })
        break
      default:
        break
    }
    this.fileChange.emit([this.files_list, this.info]);
  }

  

}
