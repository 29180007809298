import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ComponentFactoryResolver,
  ViewChild,
  ViewContainerRef,
  OnChanges,
  SimpleChanges
} from "@angular/core";
import { ItemComponent } from "../item.component";
import { ProductsService } from "src/app/shared/services/products.service";
import { forkJoin } from "rxjs";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { GqlService } from "src/app/shared/gql/service/gql.service";

@Component({
  selector: "trm-relation-wrap",
  templateUrl: "./relation-wrap.component.html",
  styleUrls: ["./relation-wrap.component.sass"]
})
export class RelationWrapComponent implements OnChanges {
  @Input() item_type: string; // тип (category, etc)
  @Input() relation: any[]; // ?
  @Input() info; // ?
  @Input() parent_id; // ?
  // @Input() item: any
  @Input() active_values_ids = []; // ->
  // @Input() activeItems = [] // вместо active_values_ids

  @Output() relationChange = new EventEmitter<any>();
  onRelationChange() {
    this.relationChange.emit([
      this.activeItems,
      this.info
    ]);
  }

  // no need
  @Input() disabled;
  @Output() relationAdd = new EventEmitter<any>();

  end_list_flag = true;

  items: any[] = [];
  activeItems: any[] = [];
  filteredActiveItems: any[] = [];
  // relationMap: any

  isTotalOpened: boolean = true;
  isOpened: boolean = false;

  configuration: any;

  // new
  pageInfo = {
    curPage: 0,
    pageCount: 0,
    totalCount: 150,
    perPage: 20
  };
  sorts: any[] = [
    {
      dir: "asc",
      prop: "name"
    }
  ];
  searchQuery: string = "";
  filters: string[] = [];
  loading: boolean = false;
  // selected = []

  // new end

  @ViewChild("item2Container", { read: ViewContainerRef }) container;
  componentRef;

  @ViewChild(DatatableComponent, { read: false }) table: DatatableComponent;

  constructor(
    private productsService: ProductsService,
    private resolver: ComponentFactoryResolver,
    private gqlService: GqlService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {
      let chng = changes[propName];
      let cur = JSON.stringify(chng.currentValue);
      if (propName === "info" && cur) {
        // console.log('Lets update lists, info:', chng.currentValue)
        this.configuration = this.productsService.getModel(this.info["rest"]);

        // получаем данные, получаем активные данные, обновляем списки активных и неактивных записей
        this.activeItems = this.convertData(this.active_values_ids);
        this.filteredActiveItems = this.activeItems;

        this.isOpened = this.activeItems.length === 0;
        this.getRows().then((res: any) => {});
      }
    }
  }

  // select/deselect items
  onChange(event: any) {
    var value = event.target.value;
    var isChecked = event.target.checked;
    // console.log(value, isChecked)
    if (isChecked) {
      const item = this.items.find(i => {
        return i.id === parseInt(value);
      });
      if (item) {
        item.isChecked = true;
        this.activeItems = [item, ...this.activeItems];
        this.filteredActiveItems = this.activeItems;
        this.items = this.items.filter(i => i.id !== item.id);
      }
    } else {
      const item = this.activeItems.find(i => {
        return i.id === parseInt(value);
      });
      if (item) {
        item.isChecked = false;
        this.items = [item, ...this.items];
        this.activeItems = this.activeItems.filter(i => i.id !== item.id);
        this.filteredActiveItems = this.activeItems;
      }
    }
    console.log(this.items, this.activeItems);
    this.onRelationChange();
  }

  convertData = data =>
    data.map(v => {
      const item: any = Object.values(v)[0];
      item.isChecked = true;
      return item;
    });

  getRows = async () => {
    this.loading = true;
    return new Promise((res, rej) => {
      // чтобы получить свободные
      // _not: {categoryHasCategoriesByParentId: {}} etc
      const where = {
        id: {
          _nin: this.activeItems.map(i => i.id)
        }
      };
      this.gqlService
        .getItems({
          type: this.info["rest"],
          relationType: this.info["free_type"],
          page: this.pageInfo.curPage,
          where: where,
          sort: undefined,
          qty: this.pageInfo.perPage,
          search: this.searchQuery,
          filters: undefined,
          onlyFree: true
        })
        .subscribe(({ data, count, pageInfo, isLastPage }) => {
          this.pageInfo = pageInfo;
          this.end_list_flag = isLastPage; // isLastPage not always right
          // console.log(this.items, data)
          // filter new data
          this.items = [
            ...this.items,
            ...data.filter(
              item => !this.activeItems.map(i => i.id).includes(item.id)
            )
          ];
          // this.items = [...this.items, ...data];
          this.loading = false;
          // console.log(this.items, this.activeItems);
          res({ items: data, pageInfo: this.pageInfo }); // res(any)
        });
    });
  };

  onNextPage() {
    this.pageInfo.curPage += 1;
    this.getRows().then(res => {});
  }

  onSearch(request: string) {
    this.pageInfo.curPage = 0;
    this.searchQuery = request;
    this.items = [];
    this.getRows().then(res => {});
  }

  onSearchForActive(query) {
    const val = query.toLowerCase();
    this.filteredActiveItems = this.activeItems.filter(
      d => d.name.toLowerCase().indexOf(val) !== -1 || !val
    );
    // table is undefined !?
    // this.table.offset = 0;
  }

  // для действий в relations
  onItemAction(item_id: any, action: string) {
    console.log("Let's " + action + " item " + item_id);
    switch (action) {
      case "open":
        this.createComponent(action, 0, item_id);
        break;
      case "copy":
        this.createComponent(action, 0, item_id);
        break;
      case "delete":
        this.deleteItem(item_id);
        break;
    }
  }

  deleteItem(item_id) {
    this.gqlService
      .deleteItem(item_id, this.info["rest"])
      .subscribe((response: any) => {
        console.log(response);
        this.items.forEach((item, key) => {
          if (item.id == response.id) {
            this.items.splice(key, 1);
          }
        });
      });
  }

  // для создания нового компонента Item
  onAddItem(action: string) {
    console.log("Need to " + action + " new item");
    this.createComponent(action, 0, "");
  }
  addItemCallback(event) {
    if (event.status == "success") {
      if (event.type == "create") {
        this.items = [event.data, ...this.items];
        // console.log(this.items)
      }
      if (event.type == "update") {
        // искать в items и activeItems !!!
        this.items.forEach((item, key) => {
          if (item.id == event.data.id) {
            this.items[key] = event.data;
          }
        });
      }
    }
  }
  createComponent(type, seq, item_id) {
    this.container.clear();
    const factory = this.resolver.resolveComponentFactory(ItemComponent);
    this.componentRef = this.container.createComponent(factory);
    this.componentRef.instance.type = type;
    this.componentRef.instance.seq = seq + 1;
    this.componentRef.instance.item_id = item_id;
    this.componentRef.instance.configuration = this.configuration;
    this.componentRef.instance.item_type = this.info["rest"];
    // this.componentRef.instance.relations = this.relations;
    this.componentRef.instance.output.subscribe(event => {
      this.addItemCallback(event);
      this.componentRef.destroy();
    });
  }
}
