import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
  EventEmitter,
  Output
} from "@angular/core";
import { ProductsService } from "src/app/shared/services/products.service";
import { GqlService } from "src/app/shared/gql/service/gql.service";
import { forkJoin } from "rxjs";

@Component({
  selector: "trm-item",
  templateUrl: "./item.component.html",
  styleUrls: ["./item.component.sass"]
})
export class ItemComponent implements OnInit {
  @Input() items_type: string = "categories";
  @Input() configuration: any = {};
  @Input() relations: any[] = [];
  @Input() item: any = {};
  @Input() item_id: string = "";
  @Input() isPopup = false;
  @Input() action = "update";

  @Input() type: string = "open";
  @Input() seq: number = 0;
  @Output() output = new EventEmitter();

  item_info: any = {};
  isOpened: boolean = true;

  relationsObj: any = {};

  constructor(
    private productsService: ProductsService,
    private gqlService: GqlService
  ) {}

  ngOnInit() {
    if (this.item_id === undefined || this.item_id === "") {
      this.configuration.inputs &&
        this.configuration.inputs.forEach(el => {
          const defaultValues = this.configuration.inputs_default || {};
          this.item_info[el] = defaultValues[el] || "";
        });

      this.configuration.textareas &&
        this.configuration.textareas.forEach(el => {
          const defaultValues = this.configuration.textareas_default || {};
          this.item_info[el] = defaultValues[el] || "";
        });

      this.configuration.switches &&
        this.configuration.switches.forEach(el => {
          const defaultValues = this.configuration.switches_default || {};
          this.item_info[el] = defaultValues[el] || 0;
        });

      return;
    }

    this.gqlService
      .getItem(this.configuration.item_type, this.item_id)
      .subscribe((item: any[]) => {
        // console.log(item)
        this.item_info = item;
        console.log(this.item_info)
      });
  }

  getStylesBySeq() {
    const left = 50 + 30 * this.seq;
    const top = 10 + 10 * this.seq;
    return {
      zIndex: this.seq + 21,
      paddingLeft: left + "px",
      paddingTop: top + "px",
      paddingBottom: "5px"
    };
  }

  isExistsInDb() {
    return this.item_info.id !== undefined;
  }

  // onColumnChanged(value) {
  //   console.log(value);
  //   this.item_info[value[1]] = value[0];
  //   // console.log(this.item_info)
  // }

  onRelationChanged(data) {
    // console.log(data);
    const [value, info] = data
    const key = info['item_col']
    // console.log(this.item_info[key])
    const currentData = this.item_info[key].map(v => Object.values(v)[0])

    // this.relationsObj[key] = value;
    this.relationsObj[key] = {
      deleting: currentData
        .filter(i => !value.map(v=>v.id).includes(i.id))
        .map(i => info["set_output"](this.item_info.id, i.id)),
      inserting: value
        .filter(i => !currentData.map(v=>v.id).includes(i.id))
        .map(i => info["set_output"](this.item_info.id, i.id))
    }
    console.log(this.relationsObj);
  }

  onRelationAdd(item_col) {
    // ?
  }

  onChange(event: any, column: string, type = "text") {
    if (type === "checkbox") {
      this.item_info[column] = event.target.checked ? 1 : 0;
    } else {
      this.item_info[column] = event.target.value;
    }
  }

  onSave(withExit = false) {
    //   // загружаем файлы если необходимо
    // console.log(this.item_info)
    // console.log(this.configuration.item_type)

    // let item_info = this.item_info
    // console.log(this.item_info);
    const item_info: any = Object.keys(this.item_info).reduce((sum, prop) => {
      // console.log(typeof this.item_info[prop] === "object");
      sum =
        this.item_info[prop] === null ||
        typeof this.item_info[prop] === "object"
          ? sum
          : { ...sum, [prop]: this.item_info[prop] };
      return sum;
    }, {});
    // console.log(item_info)
    const { id, ...item } = item_info;

    // обновление вместе с relations
    console.log(this.relationsObj);
    const observables = [
      this.gqlService.updateItem(item, this.configuration.item_type, id),
      ...Object.keys(this.relationsObj).map(key => {
        return this.gqlService.updateRelation({
          type: key,
          deletingItems: this.relationsObj[key].deleting,
          insertingItems: this.relationsObj[key].inserting
        });
      })
    ];
    forkJoin(observables).subscribe(
      res => {
        // обновляем данные
        // console.log(res)
        // this.gqlService
        //   .getItem(this.configuration.item_type, this.item_id)
        //   .subscribe((item: any[]) => {
        //     this.item_info = item;
        //     if (withExit) {
        //       this.output.next({
        //         type: "update",
        //         status: "success",
        //         data: item
        //       });
        //     }
        //   });
        if (withExit) {
          this.output.next({
            type: "update",
            status: "success",
            data: this.item_info
          });
        }
      },
      error => {
        this.output.next({
          type: "update",
          status: "error"
        });
      }
    );
  }

  onCreate(withExit = false) {
    // нужно понять текущая категория или другая
    // это только для текущей категории

    this.gqlService
      .createItem(this.item_info, this.configuration.item_type)
      .subscribe(
        item => {
          console.log(item);
          this.item_info = item;
          if (withExit) {
            this.output.next({
              type: "create",
              status: "success",
              data: item
            });
          }
        },
        error => {
          console.log(error);
          this.output.next({
            type: "create",
            status: "error"
          });
        }
      );

    // this.productsService.createItem(item_info, this.configuration.item_type)
    //   .subscribe(
    //     (item: any) => {
    //       console.log(item, this.item_info)
    //       this.item_info = item
    //       if(withExit){
    //         this.output.next({
    //           'type': 'create',
    //           'status': 'success',
    //           'data': item
    //         })
    //       }
    //     },
    //     (error) => {
    //       this.output.next({
    //         'type': 'create',
    //         'status': 'error'
    //       })
    //     }
    //   )
  }

  onCancel() {
    this.output.next({
      type: "cancel",
      status: "success",
      data: {}
      // 'data': this.item_info
    });
  }
}
