export class Category {
    constructor(
        public name: string,
        public full_name: string,
        public productPage_ids: string,
        public id?: number
    ){}

    inputs: string[]
    textareas: string[]
    switches: string[]
    labels: string[]
    image: string
    relations: string[][]

    static getConfiguration(): object{
        return {
            "item_type": "categories",
            "item_title": "Категории",
            "item_model": "Category",
            "hiddens": ["id"],
            "hiddens_obj": {
                "id": 'ID'
            },
            "inputs": ["name"],
            "inputs_obj": {
                "name": 'Наименование'
            },
            "textareas": ["full_name", "description", "meta_descr", "meta_keywords"],
            "textareas_obj": {
                "full_name": 'Полное наименование',
                "description": 'Описание',
                "meta_descr": 'Meta-Description',
                "meta_keywords": 'Meta-Keywords',
            },
            "switches": ["visibility"],
            "switches_obj": {
                "visibility": 'Доступ',
            },
            "switches_default": {
                "visibility": 1
            },
            "labels": ["rating", "createdat", "updatedat"],
            "labels_obj": {
                "rating": 'Посещаемость',
                "createdat": 'Создано',
                "updatedat": 'Обновлено'
            },
            "images": [
                {
                    "name": "Изображения",
                    "folder": 'store',
                    "rest": "categoryImages",
                    "model": "CategoryImage",
                    "item_col": "category_has_images",
                    "set_output": (id1, id2) => ({category_id: id1, image_id: id2})
                }
            ],
            "relations": ["productPage_ids", "daughter_ids", "parent_ids", "filter_ids"],
            // "relations_urls": ["productPage", "daughter", "parent", "filter"],
            "relations_info": [
                {
                    "name": "Товары",
                    "rest": "productPages",
                    "model": "ProductPage",
                    "item_col": "category_has_productpages",
                    "set_output": (id1, id2) => ({category_id: id1, productpage_id: id2})
                },
                {
                    "name": "Подкатегории",
                    "rest": "categories",
                    "model": "Category",
                    "item_col": "categoryHasCategoriesByParentId",
                    "set_output": (id1, id2) => ({parent_id: id1, daughter_id: id2})
                },
                {
                    "name": "Родительские категории",
                    "rest": "categories",
                    "model": "Category",
                    "item_col": "category_has_categories",
                    "set_output": (id1, id2) => ({daughter_id: id1, parent_id: id2})
                },
                // {
                //     "name": "Фильтры",
                //     "rest": "filters",
                //     "model": "Filter",
                //     "item_col": "filter_ids",
                //     "item_col_json": "filter_ids_json"
                // }
            ],
            "sort_options": [
                {
                    // labels??
                    "label": 'Обновлено',
                    "value": 'updatedat'
                },
                {
                    "label": 'По популярности',
                    "value": 'rating'
                },
                {
                    "label": 'По имени',
                    "value": 'name'
                }
            ],
            // "filters": ["visibilityOff", "prevDescrEmpty"],
            // "filters_obj": {
            //     "visibilityOff":{
            //         "label": "Скрытые",
            //         "paramName": '&filter[visibility]',
            //         "paramValues": [0]
            //     },
            //     "prevDescrEmpty": {
            //         "label": "C пустым описанием",
            //         "paramName": '&filter[description]',
            //         "paramValues": ['']
            //     }
            // }
        }
    }

    // fromREST(cat: any[]){
    //     this.inputs = [cat['name'], cat['full_name']]
    //     this.textareas = [cat['description']]
    //     this.switches = [cat['visibility']] 
    //     this.labels = [cat['rating'], cat['createdAt'], cat['updatedAt']]
    //     this.image = cat['image']
    //     this.relations = [cat['productPage_ids'], cat['parent_ids'], cat['child_ids']]
    // }

    // toREST(): any[]{
    //     // return объединение 6 массивов 
    //     var cat = []
    //     this.inputs.forEach(el => {
    //         cat.push(el)
    //     });
    //     this.textareas.forEach(el => {
    //         cat.push(el)
    //     });
    //     this.switches.forEach(el => {
    //         cat.push(el)
    //     });
    //     this.labels.forEach(el => {
    //         cat.push(el)
    //     });
    //     cat.push(this.image)
    //     this.relations.forEach(el => {
    //         cat.push(el)
    //     });
    //     return cat
    // }

}