import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// const routes: Routes = [];
const routes: Routes = [
  {path: '', redirectTo: 'system/cms/categories', pathMatch: 'full'}
  // { path: 'heroes', component: HeroesComponent }
];

@NgModule({
  // imports: [RouterModule.forRoot(routes)],
  // exports: [RouterModule]
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
