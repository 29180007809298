import { Component, OnInit, Input, Output, EventEmitter, ComponentFactoryResolver, ViewChild, ViewContainerRef, OnChanges, SimpleChanges } from '@angular/core';
import { CheckboxControlValueAccessor } from '@angular/forms';
import { ItemComponent } from '../item.component';
import { ProductsService } from 'src/app/shared/services/products.service';
import { forkJoin } from 'rxjs';
import { GqlService } from 'src/app/shared/gql/service/gql.service';

@Component({
  selector: 'trm-prices-wrap',
  templateUrl: './prices-wrap.component.html',
  styleUrls: ['./prices-wrap.component.sass']
})
export class PricesWrapComponent implements OnInit, OnChanges {

  @Input() item_type: string
  @Input() relation: any[]
  @Input() item: any

  @Input() info

  @Input() parent_id
  @Input() active_values_ids
  @Input() disabled

  @Input() modeId
  @Input() warehouseIds

  @Output() relationChange = new EventEmitter<any>();
  onRelationChange(){
    // this.updateItemsChecking()
    this.relationChange.emit([this.active_values_ids, this.info["item_col"]]);
  }
  @Output() relationAdd = new EventEmitter<any>();

  end_list_flag = true

  items: any[] = []
  activeItemsSet: Set<object>
  activeItems: any[]
  // relationMap: any

  isTotalOpened: boolean = true
  isOpened: boolean = false

  configuration: any


  // new

  pageInfo = {
    curPage: 0,
    pageCount: 0,
    totalCount: 150,
    perPage: 20
  }
  sorts: any[] = [
    {
      dir: 'asc',
      prop: 'name'
    }
  ]
  searchQuery: string = ''
  filters: string[] = []
  loading: boolean = false
  // selected = []

  // new end

  constructor(
    private productsService: ProductsService,
    private gqlService: GqlService
  ) {}

  ngOnInit() {
    this.getRows()
  }

  ngOnChanges(changes: SimpleChanges){

    // for(let propName in changes){
    //   let chng = changes[propName]
    //   let value = chng.currentValue
    //   let cur = JSON.stringify(chng.currentValue)

    //   // console.log(propName)
    //   if(propName === 'active_values_ids'){
    //     // this.active_items
    //     // console.log(cur)
    //   }
    //   if(propName == "info" && cur){

    //     this.getRows()

    //   }  
    // }
  }

  onChange(index, event: any){
    let {name, value, checked} = event.target
    if(name === 'isavailable'){
      value = checked ? 1 : 0
    }
    console.log(index, name, value)
    // this.items[index][name] = value
    this.items = this.items.map((item, i)=>{
      if(i === index){
        item[name] = value
      }
      return item
    })
    console.log(this.items)
  }

  onSave(index, row){
    console.log(row, this.modeId, row.id)
    const item_info: any = Object.keys(row).reduce((sum, prop) => {
      // console.log(typeof row[prop] === "object");
      sum =
        row[prop] === null || typeof row[prop] === "object"
          ? sum
          : { ...sum, [prop]: row[prop] };
      return sum;
    }, {});
    const {id, ...item} = item_info
    console.log(id, item)
    this.gqlService.updateItem(item, 'warehouse_has_modes', id).subscribe(
      (res)=>{
        console.log(res)
        this.items[index] = res
      },  
      (error)=>{
        console.log('Error:', error)
      }
    )
    // this.productsService.updateItem(row, 'warehouseHasModes', row.id)
    //   .subscribe((res)=>{
    //     console.log(res)
    //     this.items[index] = res
    //   })
  }

  getRows = async () => {

    console.log('get data')

    this.configuration = this.productsService.getModel(this.info["rest"])

    // this.loading = true

    // this.items = this.warehouseIds.map(v=>Object.values(v)[0])
    this.items = this.warehouseIds
    console.log(this.items)

    // let itemsJoin = this.warehouseIds.map(id => {
    //   return this.productsService.getNextItems({type: 'warehouseHasModes', filters: '&filter[Mode_id]=' + this.modeId + '&filter[Warehouse_id]=' + id })
    // })
    // forkJoin(itemsJoin).subscribe((result: any)=>{
    //   this.items = result.map((res)=>{
    //     return res.body[0]
    //   })
    //   console.log(this.items)
    //   this.loading = false
    // })

    
  }

}
