import gql from "graphql-tag";

import { Model } from "./model.interface";

const getMany = gql`
  query getWarehousesWarehouseHasModes(
    $limit: Int
    $where: warehouse_has_mode_bool_exp
    $order_by: [warehouse_has_mode_order_by!]
    $offset: Int
  ) {
    warehouse_has_mode(
      limit: $limit
      where: $where
      order_by: $order_by
      offset: $offset
    ) {
      warehouse {
        id
        name
        shipping_time
        city
      }
      id
      price
      days
      quantity
      isavailable
    }
    warehouse_has_mode_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

const getOne = gql`
  query getWarehouseHasMode($id: bigint!) {
    warehouse_has_mode_by_pk(id: $id) {
      warehouse {
        id
        name
        shipping_time
        city
      }
      id
      price
      days
      quantity
      isavailable
    }
  }
`;

const createOne = gql`
  mutation createWarehouseHasMode($data: [warehouse_has_mode_insert_input!]!) {
    insert_warehouse_has_mode(objects: $data) {
      returning {
        warehouse {
          id
          name
          shipping_time
          city
        }
        id
        price
        days
        quantity
        isavailable
      }
    }
  }
`;

const createMany = gql`
  mutation createWarehouseHasModes($data: [warehouse_has_mode_insert_input!]!) {
    insert_warehouse_has_mode(objects: $data) {
      returning {
        warehouse {
          id
          name
          shipping_time
          city
        }
        id
        price
        days
        quantity
        isavailable
      }
    }
  }
`;
const updateOne = gql`
  mutation updateWarehouseHasModeById(
    $id: bigint!
    $data: warehouse_has_mode_set_input!
  ) {
    update_warehouse_has_mode(_set: $data, where: { id: { _eq: $id } }) {
      returning {
        warehouse {
          id
          name
          shipping_time
          city
        }
        id
        price
        days
        quantity
        isavailable
      }
    }
  }
`;
const updateWithRelations = gql`
  mutation updateWarehouseHasModeWithRelations(
    $where: warehouse_has_mode_bool_exp!
    $data: warehouse_has_mode_set_input!
  ) {
    update_warehouse_has_mode(_set: $data, where: $where) {
      returning {
        warehouse {
          id
          name
          shipping_time
          city
        }
        id
        price
        days
        quantity
        isavailable
      }
    }
  }
`;
const deleteOne = gql`
  mutation deleteWarehouseHasMode($id: bigint!) {
    delete_warehouse_has_mode(where: { id: { _eq: $id } }) {
      returning {
        warehouse {
          id
          name
          shipping_time
          city
        }
        id
        price
        days
        quantity
        isavailable
      }
    }
  }
`;
const deleteMany = gql`
  mutation deleteWarehouseHasModes($where: warehouse_has_mode_bool_exp!) {
    delete_warehouse_has_mode(where: $where) {
      returning {
        warehouse {
          id
          name
          shipping_time
          city
        }
        id
        price
        days
        quantity
        isavailable
      }
    }
  }
`;

export const WarehouseHasMode: Model = {
  getOne,
  getMany,
  createOne,
  createMany,
  updateOne,
  updateWithRelations,
  deleteOne,
  deleteMany
};
