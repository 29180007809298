import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";

import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";

import { Apollo } from 'apollo-angular';

import { forkJoin } from "rxjs/observable/forkJoin";
import { tap, map } from "rxjs/operators";
import { observable, of } from "rxjs";
import { ApolloQueryResult } from 'apollo-client';
import { Category } from '../models/category.model';
import { Model } from '../models/model.interface';
import { Mode } from '../models/mode.model';
import { Product } from '../models/product.model';
import { ProductPage } from '../models/productPage.model';
import { Warehouse } from '../models/warehouse.model';
import { Document } from '../models/document.model';
import { Image } from '../models/image.model';
import { WarehouseHasMode } from '../models/warehouse_has_mode.model';
import { ExecutionResult } from 'graphql';

import * as relations from '../models/relations.model'

// import { Category } from "../models/category.model";
// import { Product } from "../models/product.model";
// import { ProductPage } from "../models/productPage.model";
// import { Mode } from "../models/mode.model";
// import { Filter } from "../models/filter.model";
// import { Parameter } from "../models/parameter.model";
// import { News } from "../models/news.model";
// import { Article } from "../models/article.model";
// import { Automation } from "../models/automation.model";


// const httpOptions = {
//   headers: new HttpHeaders({
//     'Access-Control-Allow-Origin': '*',
//     // 'Content-Type':  'application/json',
//     'Content-Type':  'application/x-www-form-urlencoded',
//     // 'Authorization': 'Basic MTAxLXRva2VuOg=='
//   }),
// //   observe: 'response'
// }

@Injectable()
export class GqlService {
  constructor(
    private apollo: Apollo,
    private http: HttpClient
  ) {}

  url: String = 'http://api.овенспб.рф:3000/webhooks/'
  
  // token: string = 'TermonikaSecretKey'
  token: string

  // token = "?access-token=fLixc1c9KdKlKoB6kF9pflM4a8wkI0eb"

  // relationsData: any[] = undefined

  // relationsTable = {
  //     "productPage": "productPages",
  //     "parent": "categories",
  //     "child": "categories",
  //     "filter": "filters"
  // }

  setToken(token: string) {
    this.token = token;
  }

  sendQuery({query, variables}): Observable<ApolloQueryResult<unknown>> {
    // console.log(query, variables)

    // this.apollo.getClient().queryManager.fetchQuery()
    return this.apollo.query({
      query,
      variables,
      fetchPolicy: 'network-only',
      context: {
        headers: new HttpHeaders({
          'x-hasura-admin-secret': this.token
        })
        // headers: {
          // Authorization: 'Bearer ' + ProductsService.authKey,
          // 'site-auth-key': ProductsService.siteAuthKey
        // }
      }
    })
    // .pipe(
    //   tap(
    //     data => {},
    //     error => alert("Ошибка: " + error)
    //   )
    // );
  }

  watchQuery({query, variables}): Observable<ApolloQueryResult<unknown>> {
    return this.apollo.watchQuery({
      query,
      variables,
      fetchPolicy: 'cache-and-network',
      context: {
        headers: new HttpHeaders({
          'x-hasura-admin-secret': this.token
        })
      }
    }).valueChanges
  }

  sendMutation({query, variables}): Observable<ExecutionResult<unknown>> {
    // console.log(query, variables)
    return this.apollo.mutate({
      mutation: query,
      variables,
      context: {
        headers: {
          'x-hasura-admin-secret': this.token
        }
      }
    })
    // .pipe(
    //   tap(
    //     data => {},
    //     error => alert("Ошибка: " + error)
    //   )
    // );
  }

  formExpression = (str = '') => {
    const badSigns: string[] = [' ', '-', '.', ',', ':', '/' /*, '\\'*/];
    const badEnglishLetters: string[] = [
      'A',
      'B',
      'C',
      'E',
      'H',
      'K',
      'O',
      'M',
      'P',
      'T',
      'X'
    ];
    const badRussianLetters: string[] = [
      'А',
      'В',
      'С',
      'Е',
      'Н',
      'К',
      'О',
      'М',
      'Р',
      'Т',
      'Х'
    ];
    const isLetter = (c) => {
      return c.toLowerCase() !== c.toUpperCase();
    }
    const expression = str.split('').reduce((sum, cur) => {
      const curToUpperCase = cur.toLocaleUpperCase();
      if (badSigns.includes(cur)) return [...sum, `(${badSigns.join('|')})`];
      let indexOf = badEnglishLetters.indexOf(cur.toLocaleUpperCase());
      if (indexOf > -1)
        return [
          ...sum,
          `(${badEnglishLetters[indexOf]}|${
            badRussianLetters[indexOf]
          }|${badEnglishLetters[
            indexOf
          ].toLocaleLowerCase()}|${badRussianLetters[
            indexOf
          ].toLocaleLowerCase()})`
        ];
      indexOf = badRussianLetters.indexOf(cur.toLocaleUpperCase());
      if (indexOf > -1)
        return [
          ...sum,
          `(${badEnglishLetters[indexOf]}|${
            badRussianLetters[indexOf]
          }|${badEnglishLetters[
            indexOf
          ].toLocaleLowerCase()}|${badRussianLetters[
            indexOf
          ].toLocaleLowerCase()})`
        ];
      return [
        ...sum,
        isLetter(curToUpperCase) ? `(${curToUpperCase}|${curToUpperCase.toLocaleLowerCase()})` : `${curToUpperCase}`
        // isNaN(parseInt(curToUpperCase)) ? `(${curToUpperCase}|${curToUpperCase.toLocaleLowerCase()})` : `${curToUpperCase}`
      ];
    }, []);
    return `%${expression.join('')}%`;
  };



  getItems({type, relationType = undefined, ...rest}): Observable<any> {
    const { page=0, sort=[{createdat: 'desc'}], qty=50, conditionIn='', searchCol='name', where, search='', filters='', onlyFree=false, ids } = rest
    // console.log(this.getModel(type))
    
    const finalWhere = where ? 
    {
      ...where,
      [searchCol]: {
        _similar: this.formExpression(search)
      }
    } : {
      id: {
        _in: ids
      },
      [searchCol]: {
        _similar: this.formExpression(search)
      }
    }
    if(relationType && onlyFree){
      console.log('type', type, relationType)
      finalWhere._not = {
        [relationType]: {}
      }
    }
    
    return this.sendQuery({
      query: this.getModel(type).getMany,
      variables: {
        limit: qty,
        offset: (page)*qty,
        where: finalWhere,
        order_by: sort
      }
    })
    .pipe(map(({data}) => {
      // console.log(data)
      const count = data[Object.keys(data)[1]].aggregate.count
      const pageInfo = {
        curPage: page,
        totalCount: count,
        pageCount: Math.ceil(count/qty),
        perPage: qty
      }
      return {
        data: data[Object.keys(data)[0]],
        count,
        pageInfo,
        isLastPage: page >= pageInfo.pageCount
      }
    }));
  }

  getItem(type: string, id: string): Observable<unknown> {
    return this.sendQuery({
      query: this.getModel(type).getOne,
      variables: {id}
    })
    .pipe(map(({data})=>{
      return data[Object.keys(data)[0]]
    }))
  }

  getModel(type: string): Model {
    // console.log(Category, type)
    switch (type) {
      case "categories":
        return Category
      case "productPages":
        return ProductPage;
      case "products":
        return Product;
      case "modes":
        return Mode;
      case "warehouse_has_modes":
        return WarehouseHasMode;
      case "warehouses":
        return Warehouse;
      case "documents":
        return Document
      case "images":
        return Image
      // case "filters":
      //   return Filter.getConfiguration();
      // case "parameters":
      //   return Parameter.getConfiguration();
      // case "articles":
      //   return Article.getConfiguration();
      // case "news":
      //   return News.getConfiguration();
      // case "automations":
      //   return Automation.getConfiguration();
      default:
        return undefined;
    }
  }

  updateItem(item: any, type: string, id: number): Observable<unknown> {
    console.log(item, id, type)
    // return of(true)
    return this.sendMutation({
      query: this.getModel(type).updateOne,
      variables: {id, data: item}
    }).pipe(map(({data})=>{
      // console.log(data)
      return Object.values(data)[0].returning[0]
    }))
  }

  updateRelation({type, deletingItems = [], insertingItems = []}: any): Observable<unknown> {
    const where = {"_or": deletingItems.map(item=>{
      for (const key in item) {
        item[key] = {"_eq": item[key]}
      }
      return item
    })}
    // return of({relation: relations[type], where, insertingItems})
    return this.sendMutation({
      query: relations[type],
      variables: {
        where, 
        data: insertingItems
      }
    })
  }

  createItem(item: any, type: string): Observable<unknown> {
    console.log(item, type)
    // return of(true)
    return this.sendMutation({
      query: this.getModel(type).createOne,
      variables: {data: item}
    })
    .pipe(map(({data})=>{
      // console.log(data)
      return Object.values(data)[0].returning[0]
    }))
  }

  deleteItem(id: string, type: string): Observable<unknown> {
    console.log(id, type)
    return this.sendMutation({
      query: this.getModel(type).deleteOne,
      variables: {id}
    })
    .pipe(map(({data})=>{
      console.log(data)
      return Object.values(data)[0].returning[0]
    }))
  }

  // encodeDataToURL(data) {
  //   var out = new Array();
  //   for (var key in data) {
  //     let str = "";
  //     if (typeof data[key] == "object") {
  //       str = JSON.stringify(data[key]);
  //     } else {
  //       str = data[key];
  //     }
  //     out.push(key + "=" + encodeURIComponent(str));
  //   }
  //   return out.join("&");
  // }

  // getDocuments(ids: any[], type: string = "documents"): Observable<any[]> {
  //   var documents = [];
  //   ids.forEach(id => {
  //     documents.push(this.getItem(type, id));
  //   });
  //   return forkJoin(documents);
  // }

  uploadFiles(files, type = 'upload', path = ''): Observable<any> {
    var formData: any = new FormData();
    for (const file of files) {
      // if(file['type'] === "image/jpeg" || file['type'] === "image/png" || file['type'] === "image/gif" || file['type'] === "image/svg+xml" ){
        formData.append('files', file)
      // }
    }
    console.log(type, path)
    for(let [name, value] of formData) {
      console.log(`${name} = ${value}`);
    }
    // return null
    return this.http.post(
      this.url + "upload" + "?type=" + type + "&path=" + path,
      formData,
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          //   'Content-Type':  'undefined',
          //   'Content-Type':  'multipart/form-data',
          //   'Authorization': 'Basic MTAxLXRva2VuOg=='
          // 'Authorization': 'Bearer ' + 'this.token'
        })
      }
    );
  }

  // getToken(email, password): Observable<any> {
  //   return this.http.get(
  //     this.url + `token?email=${email}&password=${password}`,
  //     httpOptions
  //   );
  // }
}
