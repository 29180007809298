import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ItemsListRoutingModule } from './items-list-routing.module';
import { ItemsListComponent } from './items-list.component';
import { ItemComponent } from './item/item.component';
import { RelationWrapComponent } from './item/relation-wrap/relation-wrap.component';
import { ImagesWrapComponent } from './item/images-wrap/images-wrap.component';
import { FilesWrapComponent } from './item/files-wrap/files-wrap.component';
import { FileWrapComponent } from './item/files-wrap/file-wrap/file-wrap.component';
import { ImageWrapComponent } from './item/images-wrap/image-wrap/image-wrap.component';
import { TableComponent } from './components/table/table.component';
// import { ItemmComponent } from './itemm/itemm.component';

import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';

import { NgxDatatableModule } from '@swimlane/ngx-datatable'
import { PricesWrapComponent } from './item/prices-wrap/prices-wrap.component';

@NgModule({
  imports: [
    CommonModule,
    ItemsListRoutingModule,
    NgxDatatableModule,
    ConfirmDialogModule
  ],
  exports: [
    ItemsListComponent
  ],
  entryComponents: [
    ItemComponent,
    TableComponent
  ],
  declarations: [ItemsListComponent, ItemComponent, RelationWrapComponent, PricesWrapComponent, ImagesWrapComponent, FilesWrapComponent, FileWrapComponent, ImageWrapComponent, TableComponent],
  providers: [ConfirmationService]
})
export class ItemsListModule { }
