import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../shared/services/auth.service";

@Component({
  selector: "trm-system",
  templateUrl: "./system.component.html",
  styleUrls: ["./system.component.sass"]
})
export class SystemComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    if(!this.authService.isLoggedIn()){
      console.log('no auth')
      this.router.navigateByUrl("/auth/login");
    }
  }

  ngOnInit(){
    // if(!this.authService.isLoggedIn()){
    //   console.log('no auth')
    //   this.router.navigateByUrl("/auth/login");
    // }
  }

  onLogout() {
    this.authService.logout();
    this.router.navigateByUrl("/auth/login");
  }
}
