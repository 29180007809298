<div class="auth">
    <div class="auth-container">
        <div class="card">
            <header class="auth-header">
                <h1 class="auth-title">
                    <div class="logo">
                        <span class="l l1"></span>
                        <span class="l l2"></span>
                        <span class="l l3"></span>
                        <span class="l l4"></span>
                        <span class="l l5"></span>
                    </div>
                    Управление товарной базой
                </h1>
            </header>
            <div class="auth-content">
                
                <router-outlet></router-outlet>

            </div>
        </div>
    </div>
</div>