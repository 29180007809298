<p class="text-xs-center">Войдите для работы</p>
<div 
  class="alert alert-{{message.type}}"
  *ngIf="message.text"
> 
  {{message.text}} 
</div>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div 
    class="form-group" 
    [ngClass]="{'has-error': form.get('email').invalid && form.get('email').touched}"
  >
    <label for="email">Email</label>
    <input value="eguu@egu.ru" type="text" class="form-control underlined" id="email" placeholder="Введите ваш email" formControlName="email">
    <span class="form-help-text" *ngIf="form.get('email').invalid && form.get('email').touched">
      <span *ngIf="form.get('email')['errors']['required']">Email is empty</span>
      <span *ngIf="form.get('email')['errors']['email']">Thats not email</span>
    </span>
  </div>
  <div 
    class="form-group"
    [ngClass]="{'has-error': form.get('password').invalid && form.get('password').touched}"
  >
    <label for="password">Пароль</label>
    <input value="1234567890" type="password" class="form-control underlined" id="password" placeholder="Пароль" formControlName="password">
    <span class="form-help-text" *ngIf="form.get('password').invalid && form.get('password').touched">
      <span *ngIf="form.get('password')['errors']['required']">Password is empty</span>
      <span *ngIf="form.get('password')['errors']['minlength'] && form.get('password')['errors']['minlength']['requiredLength']">
        Password is {{ form.get('password')['errors']['minlength']['actualLength'] }} symbols
      </span>
    </span>
  </div>
  <div class="form-group">
    <button 
      type="submit" 
      class="btn btn-block btn-primary"
      [disabled]="form.invalid"
    >
      Войти
    </button>
  </div>
  <!-- <div class="form-group">
    <p class="text-muted text-xs-center">
      Нет аккаунта? <a [routerLink]="'/registration'">Зарегистрироваться!</a>
    </p>
  </div> -->
</form>