import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'trm-import-warehouse',
  templateUrl: './import-warehouse.component.html',
  styleUrls: ['./import-warehouse.component.sass']
})
export class ImportWarehouseComponent implements OnInit {

  max = 1
  files

  @Input() results = []
  @Output() output = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onFilesChosen(event) {
    this.files = Array.from(event.target.files);
    // this.files = event.target.files
    console.log(this.files)
    if (this.max < this.files.length) {
      event.target.value = ""
    }
  }

  onSubmit(){
    console.log(this.files)
    this.output.emit(this.files)
  }

}
