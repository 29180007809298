import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";

import { Category } from "../models/category.model";

import { forkJoin } from "rxjs/observable/forkJoin";
import { Product } from "../models/product.model";
import { ProductPage } from "../models/productPage.model";
import { Mode } from "../models/mode.model";
import { Filter } from "../models/filter.model";
import { Parameter } from "../models/parameter.model";
import { tap } from 'rxjs/operators';
import { News } from '../models/news.model';
import { Article } from '../models/article.model';
import { Automation } from '../models/automation.model';
import { observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
    // 'Content-Type':  'application/json',
    'Content-Type':  'application/x-www-form-urlencoded',
    // 'Authorization': 'Basic MTAxLXRva2VuOg=='
  }),
//   observe: 'response'
}

const httpOptions2 = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
    //   'Content-Type':  'undefined',
    //   'Content-Type':  'multipart/form-data',
    //   'Authorization': 'Basic MTAxLXRva2VuOg=='
    })
}

@Injectable()
export class ProductsService {
    constructor(private http: HttpClient) {}

    url: String = 'https://api.термоника.рф/api/'

    token = "?access-token=fLixc1c9KdKlKoB6kF9pflM4a8wkI0eb"

    relationsData: any[] = undefined

    relationsTable = {
        "productPage": "productPages",
        "parent": "categories",
        "child": "categories",
        "filter": "filters"
    }

    setToken(token){
        this.token = "?access-token=" + token
    }

    getItems(type: string): Observable<HttpResponse<any>>{
        return this.http.get<any>(this.url + type + this.token, {observe: 'response'})
        // return this.http.get<any>(this.url + type +'/index'+ + this.token, {observe: 'response'})

        //    .map((response: Response) => { return response.json()} );
    }

    getItemsByIds2(type: string, ids: string[]): Observable<any>{
        var observables = []
        ids.forEach(id => {
            // console.log(relation)
            observables.push(this.getItem(type, id))
        });
        return forkJoin(observables)
    }

    getItemsByIds(type: string, ids: string[]): Observable<any>{
        // const getTmp = () => {}
        // const tmp = '&filter[id][in][' +  + ']='
        // const str = tmp + ids.join(tmp)

        let requests = []
        let sum = ''
        for (let i = 0; i < ids.length; i++) {
            const el = ids[i];
            if ((i+1)%50 === 0){
                requests.push(sum)
                sum = ''
            }
            sum += '&filter[id][in][]=' + el
        }
        requests.push(sum)
        // console.log(requests)

        let observables = requests.reduce((sum, current, i)=>{
            sum.push(this.getNextItems({type, conditionIn: current}))
            return sum
        }, [])
        return forkJoin(observables)

    }

    getNextItems(data: any): Observable<HttpResponse<any>> {
        const { type, page=1, sort='-updatedAt,name', qty=50, conditionIn='', search='', filters='', onlyFree=false } = data
        const req = this.url + type + this.token + '&page=' + page + '&pages=' + qty + '&sort=' + sort + conditionIn + search + filters + '&onlyFree=' + onlyFree ? 'yes' : 'no'
        return this.http.get<any>(req, {observe: 'response'})
    }

    getItem(type: string, id: string): Observable<any>{
        if(type === 'news'){
            return this.http.get(this.url + `news/view` + this.token + `&id=${id}`, httpOptions)
        }
        if(type === 'articles'){
            return this.http.get(this.url + `article/view` + this.token + `&id=${id}`, httpOptions)
        }
        if(type === 'automations'){
            return this.http.get(this.url + `automation/view` + this.token + `&id=${id}`, httpOptions)
        }
        return this.http.get(this.url + `${type}/${id}` + this.token, httpOptions)
        // return this.http.get(this.url + `${type}/view` + this.token + `&id=${id}`, httpOptions)
        //    .map((response: Response) => response.json());
    }

    getModel(type: string): any{
        switch(type){
            case 'categories':
                return Category.getConfiguration()
            case 'productPages':
                return ProductPage.getConfiguration()
            case 'products':
                return Product.getConfiguration()
            case 'modes':
                return Mode.getConfiguration()
            case 'filters':
                return Filter.getConfiguration()
            case 'parameters':
                return Parameter.getConfiguration()
            case 'articles':
                return Article.getConfiguration()
            case 'news':
                return News.getConfiguration()
            case 'automations':
                return Automation.getConfiguration()
            default:
                break
        }
        return undefined
    }

    // получить item и преобразовать к нужному формату
    // getItemFromREST(type: string, id: string): Observable<any[]>{
    //     return this.http.get(this.url + `${type}/${id}`, httpOptions)
    //        .map((response: Response) => response.json())
    //        .map(() => ca);
    // }

    getRelations(relations: any[]): Observable<any[]>{
        // нужно получить несколько массивов (всех значений!), объединить их в массив и вернуть
        // узнать как, RxJs

        var relations_url = []
        relations.forEach(relation => {
            // console.log(relation)
            relations_url.push(this.getItems(relation.rest))
        });
        return forkJoin(relations_url)

        // return undefined

    }

    updateItem(item: any, type: string, id: number): Observable<any>{
        console.log('update ' + type)
        console.log(id, item)
        item = this.encodeDataToURL(item)
        // console.log(item)
        // return this.getItem(type, id.toString())
        let request = this.http.put(this.url + `${type}/${id}` + this.token, item, httpOptions)
        if(type === 'news'){
            request = this.http.put(this.url + `news/update` + this.token + `&id=${id}`, item, httpOptions)
        }
        if(type === 'articles'){
            request = this.http.put(this.url + `article/update` + this.token + `&id=${id}`, item, httpOptions)
        }
        if(type === 'automations'){
            request = this.http.put(this.url + `automation/update` + this.token + `&id=${id}`, item, httpOptions)
        }
        
        return request.pipe(
            tap( // Log the result or error
              data => {},
              error => alert("Ошибка: " + error)
            )
          )
    }

    createItem(item: any, type: string): Observable<any>{
        console.log('create ' + type)
        console.log(item)
        item = this.encodeDataToURL(item)
        // console.log(item)
        // item = JSON.stringify(item)
        // return item
        return this.http.post(this.url + `${type}` + this.token, item, httpOptions)
        .pipe(
            tap(
              data => {},
              error => alert("Ошибка: " + error)
            )
          )
        //     // .map((response: Response) => {console.log(response); response.json()});
    }

    deleteItem(id: string, type: string): Observable<any>{
        
        let request = this.http.delete(this.url + `${type}/${id}` + this.token, httpOptions)

        if(type === 'news'){
            request = this.http.delete(this.url + `news/delete` + this.token + `&id=${id}`, httpOptions)
        }
        if(type === 'articles'){
            request = this.http.delete(this.url + `article/delete` + this.token + `&id=${id}`, httpOptions)
        }
        if(type === 'automations'){
            request = this.http.delete(this.url + `automation/delete` + this.token + `&id=${id}`, httpOptions)
        }
        
        return request.pipe(
            tap( // Log the result or error
              data => {},
              error => alert("Ошибка: " + error)
            )
          )   
        // .map((response: Response) => response.json());   
    }

    encodeDataToURL(data){
        var out = new Array();
        for(var key in data){
            let str = ""
            if(typeof data[key] == "object"){
                str = JSON.stringify(data[key])
            }else{
                str = data[key]
            }
            out.push(key + '=' + encodeURIComponent(str));
        }
        return out.join('&');
    }

    getDocuments(ids: any[], type: string = "documents"): Observable<any[]>{

        var documents = []
        ids.forEach(id => {
            documents.push(this.getItem(type, id))
        });
        return forkJoin(documents)

    }

    uploadFile(file, type = "file", folder = "items"){
        var formData = new FormData()
        if(type==="file"){
            formData.append('UploadFile[files][]', file)
        }else{
            formData.append('UploadImage[files][]', file)
        }
        return this.http.post(this.url + 'upload' + this.token + '&type=' + type + '&folder=' + folder, formData, httpOptions2)
    }

    search(request: string, item_type: string = undefined){
        var searchUrl = this.url + 'searches' + this.token +'&query=' + request +'&visibility=all'
        if(item_type != undefined){
            searchUrl += '&models=' + item_type
        }
        return this.http.get(searchUrl, httpOptions)
        //    .map((response: Response) => response.json());
    }

    getOptions(type: string): Observable<any>{
        return this.http.options(this.url + `${type}` + this.token, httpOptions)
            // .map((response: Response) => response.json());
    }

    // createJsonColumns(item, configuration): any{

    //     if(configuration.relations_info){
    //         configuration.relations_info.forEach(relation_info => {
    //             item[relation_info['item_col_json']] = item[relation_info['item_col']]
    //             delete item[relation_info['item_col']]
    //         });
    //     }
    //     if(configuration.images){
    //         configuration.images.forEach(image => {
    //             item[image['item_col_json']] = item[image['item_col']]
    //             delete item[image['item_col']]
    //         }); 
    //     }
    //     if(configuration.files){
    //         configuration.files.forEach(file => {
    //             item[file['item_col_json']] = item[file['item_col']]
    //             delete item[file['item_col']]
    //         }); 
    //     }

    //     return item
    // }

    createJsonColumns(item, configuration): any{

        const {relations_info, images, files} = configuration

        let changeableColumns = {}
        if(relations_info){
            changeableColumns = relations_info.reduce((sum, obj)=>{
                return {...sum, [obj['item_col']]: obj['item_col_json']}
            }, changeableColumns)
        }
        if(images){
            changeableColumns = images.reduce((sum, obj)=>{
                return {...sum, [obj['item_col']]: obj['item_col_json']}
            }, changeableColumns)
        }
        if(files){
            changeableColumns = files.reduce((sum, obj)=>{
                return {...sum, [obj['item_col']]: obj['item_col_json']}
            }, changeableColumns)
        }

        const itemWithJsonColumns = Object.keys(item).reduce((sum, prop)=>{
            if(changeableColumns[prop]){
                return {...sum, [changeableColumns[prop]]: item[prop]}
            }else{
                return {...sum, [prop]: item[prop]}
            }
        }, {})

        return itemWithJsonColumns
    }

    createOnlyEmptyJsonColumns(configuration): any{

        console.log(configuration)
        let empty_item = []
        if(configuration.relations_info){
            configuration.relations_info.forEach(relation_info => {
                // console.log()
                empty_item[relation_info['item_col_json']] = []
            });
        }
        if(configuration.images){
            configuration.images.forEach(image => {
                empty_item[image['item_col_json']] = []
            });
        }
        if(configuration.files){
            configuration.files.forEach(file => {
                empty_item[file['item_col_json']] = []
            });
        }
        
        console.log(empty_item)
        return empty_item
    }

    getToken(email, password): Observable<any>{
        return this.http.get(this.url + `token?email=${email}&password=${password}`, httpOptions)
    }

    
    // watchData(tables): any{

    //     this.getAllData(tables)
    //     const interval = setInterval(()=>{
    //         this.getAllData(tables)
    //     }, 10000)

    // }

    // getAllData(tables){

    //     console.log('get data')
    //     // делаем запросы по всем таблицам, формируем объект, отправляем

    // }

}